import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../components/shared/AppLayout/notification/notificationSlice";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";
import useTitle from "../../../hooks/useTitle";

import styles from "./Verification.module.css";

const VerificationFailure = () => {
  useTitle("Verification Failure");

  const dispatch = useDispatch();

  // set notification upon init page load
  const effectRan = useRef(false);
  useEffect(() => {
    if (!effectRan.current) {
      dispatch(
        setNotification({
          type: "error",
          message: "Internal Server Error",
        })
      );
    }

    return () => {
      effectRan.current = true;
    };
  }, [dispatch]);

  let content;

  content = (
    <>
      <h2 className={styles.h2_error}>Error</h2>
      <FormErrMsg
        elements={[
          <p>Unable to verify email due to an internal server error.</p>,
        ]}
      />
      <p className={styles.return}>
        <Link to="/login" className={styles.link}>
          Return to login
        </Link>
      </p>
    </>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default VerificationFailure;
