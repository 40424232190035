import React from "react";
import { useGetUserProfileQuery } from "../../profile/profileApiSlice";
import SSVSettings from "../../settings/SSVSettings";
import ConstructionMsg from "../../../components/shared/ConstructionMsg/ConstructionMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import styles from "./SettingsDrawer.module.css";

const SettingsDrawer = ({ showingSSVSettings, setShowingSSVSettings }) => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  return (
    <section className={styles.container}>
      <div className={styles.topBanner}>
        <h2>Settings</h2>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className={styles.exitIcon}
          onClick={() => setShowingSSVSettings(false)}
        />
      </div>
      <SSVSettings userProfile={userProfile} />
      <div className="grid-center">
        <ConstructionMsg />
      </div>
    </section>
  );
};

export default SettingsDrawer;
