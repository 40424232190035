import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const TranslationsHelp = () => {
  const [showingTranslations, setShowingTranslations] = useState(false);

  return (
    <li className={`${styles.helpList__colItem} `}>
      <div
        className={styles.h3Row}
        onClick={() => setShowingTranslations(!showingTranslations)}
      >
        {showingTranslations ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Translations</h3>
      </div>

      {showingTranslations && (
        <div className={`${styles.TranslationsHelp} ${styles.bottomPadding}`}>
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <ul className={styles.bulletedList}>
                <li>
                  single word translations appear above each word in the target
                  sentence
                </li>
                <li>
                  sentence translation available above the toolbar to the left
                </li>
              </ul>
            </div>
          </section>
          <section className={styles.helpSection}>
            <h4>Single Word</h4>
            <div className={styles.helpSection__contents}>
              <p>
                We consider the context of each word and select the translation
                to your native language that fits best.
              </p>
              <p>
                Upon selection, the translation automatically appears above each
                word in the target sentence. Toggle its visibility by tapping
                the eye icon in the toolbar.
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Sentence</h4>
            <div className={styles.helpSection__contents}>
              <p>
                The sentences as a whole are translated with efforts to preserve
                the spirit in which they were originally intended.
              </p>
              <p>
                Toggle the visibility of the sentence translation by tapping the
                toggle button in the sentence section.
              </p>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default TranslationsHelp;
