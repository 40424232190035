import React from "react";
import useTitle from "../../../../../hooks/useTitle";

import styles from "./TermsOfService.module.css";

const TermsOfService = () => {
  useTitle("Terms of Service");

  const content = (
    <section className={styles.section}>
      <h2>Terms of Service</h2>
    </section>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default TermsOfService;
