import React, { useRef, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectNotificationShowing } from "./notification/notificationSlice";
import Notification from "./notification/Notification";
import SettingsDrawer from "../../../features/sentence/SettingsDrawer/SettingsDrawer";
import HelpDrawer from "../../../features/sentence/HelpDrawer/HelpDrawer";
import scrollToTop from "../../../utils/scrollToTop";

import styles from "./AppLayout.module.css";

const AppLayout = ({
  showingSSVSettings,
  showingSSVHelp,
  setShowingSSVSettings,
  setShowingSSVHelp,
  showingTourPopup,
  setShowingTourPopup,
  setTourActive,
  currentTourStep,
  setCurrentTourStep,
}) => {
  // scroll to top upon page navigation
  const { pathname } = useLocation();
  const appLayoutRef = useRef(null);
  useEffect(() => {
    scrollToTop(appLayoutRef);
  }, [pathname]);

  const notificationShowing = useSelector(selectNotificationShowing);

  // scroll to top upon showing notification
  useEffect(() => {
    if (notificationShowing) {
      scrollToTop(appLayoutRef);
    }
  }, [notificationShowing]);

  return (
    <div id="appLayout" ref={appLayoutRef} className={styles.appLayout}>
      {notificationShowing && <Notification />}

      <div className={styles.rowContainer}>
        <div className={styles.outletContainer}>
          <Outlet />
        </div>
        {showingSSVSettings && (
          <div className={`${styles.drawer} ${styles.drawerDeviceStyles}`}>
            <SettingsDrawer
              showingSSVSettings={showingSSVSettings}
              setShowingSSVSettings={setShowingSSVSettings}
            />
          </div>
        )}
        {showingSSVHelp && (
          <div className={`${styles.drawer} ${styles.drawerDeviceStyles}`}>
            <HelpDrawer
              showingSSVHelp={showingSSVHelp}
              setShowingSSVHelp={setShowingSSVHelp}
              showingTourPopup={showingTourPopup}
              setShowingTourPopup={setShowingTourPopup}
              setTourActive={setTourActive}
              currentTourStep={currentTourStep}
              setCurrentTourStep={setCurrentTourStep}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AppLayout;
