export default function toCamelCase(str) {
  if (!str) return "";

  // split string by spaces
  let words = str.trim().split(/\s+/);

  // map over the words array, capitalizing the first letter of each word except the first one
  let camelCased = words.map((word, index) => {
    if (index === 0) {
      // lowercase the first word
      return word.toLowerCase();
    } else {
      // capitalize the first letter and lowercase the rest
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
  });

  // join the words back together into a single string
  return camelCased.join("");
}
