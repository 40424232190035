import React, { useRef, useState, useEffect } from "react";
import { useCreateNewBookMutation } from "./adminApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AdminIndex.module.css";

const CreateNewBook = () => {
  const errRef = useRef();
  const successRef = useRef();

  const [showingForm, setShowingForm] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  // title, author, publishedDate, languageName
  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [publishedDate, setPublishedDate] = useState("");
  const [languageName, setLanguageName] = useState("");

  const [CreateNewBook, { isLoading }] = useCreateNewBookMutation();

  const clearMsgs = () => {
    setErrMsg("");
    setSuccessMsg("");
  };

  useEffect(() => {
    clearMsgs();
  }, [title, author, publishedDate, languageName]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMsgs();
    try {
      const { message, isError } = await CreateNewBook({
        title,
        author,
        publishedDate,
        languageName,
      }).unwrap();
      if (isError) {
        throw new Error(message);
      }
      setSuccessMsg(message);
      successRef.current?.focus();
    } catch (err) {
      console.error(err);
      console.log(err?.message);
      setErrMsg(err?.data?.message || err.message);
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadMsg msg="creating book..." size="1x" />
      </div>
    );
  } else {
    content = (
      <section className={styles.section}>
        <div
          onClick={() => setShowingForm(!showingForm)}
          className={styles.headerRow}
        >
          {showingForm ? (
            <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          )}
          <h2 className={styles.h2}>POST /books</h2>
        </div>
        {showingForm && (
          <>
            {errMsg && (
              <p
                ref={errRef}
                className={styles.errMsg}
                aria-live="assertive"
                role="alert"
              >
                {errMsg}
              </p>
            )}

            {successMsg && (
              <p
                ref={successRef}
                className={styles.successMsg}
                aria-live="assertive"
                role="alert"
              >
                {successMsg}
              </p>
            )}

            <form className="form__admin" onSubmit={handleSubmit}>
              {/* title */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="title">
                    title: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    autoComplete="off"
                    required
                    autoFocus
                    tabIndex="1"
                  />
                </div>
              </div>

              {/* author */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="author">
                    author: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="author"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="2"
                  />
                </div>
              </div>

              {/* publishedDate */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="publishedDate">
                    publishedDate:
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="publishedDate"
                    value={publishedDate}
                    onChange={(e) => setPublishedDate(e.target.value)}
                    autoComplete="off"
                    tabIndex="3"
                  />
                </div>
              </div>

              {/* languageName */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="languageName">
                    languageName: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="languageName"
                    value={languageName}
                    onChange={(e) => setLanguageName(e.target.value)}
                    autoComplete="off"
                    required
                    tabIndex="4"
                  />
                </div>
              </div>

              {/* submit button by default since only 1 button in this form */}
              <button className="form__submitButton" tabIndex="5">
                create book
              </button>
            </form>
          </>
        )}
      </section>
    );
  }

  return content;
};

export default CreateNewBook;
