import React from "react";
import { useUpdateUserSettingMutation } from "../profile/profileApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";

import styles from "./Settings.module.css";

const SSVSettings = ({ userProfile }) => {
  const [updateUserSetting] = useUpdateUserSettingMutation();

  const toggleSetting0 = async () => {
    const updateValue = !userProfile?.settings?.autoPlayWordTTS;
    try {
      const response = await updateUserSetting({
        setting: "autoPlayWordTTS",
        value: updateValue,
      });
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleSetting1 = async () => {
    const updateValue = !userProfile?.settings?.autoPlaySentenceTTS;
    try {
      const response = await updateUserSetting({
        setting: "autoPlaySentenceTTS",
        value: updateValue,
      });
      console.log(response);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ul className={styles.settingsList}>
      <li className={styles.setting}>
        <p className={styles.settingTxt}>
          automatically play word TTS upon selection
        </p>
        <div onClick={toggleSetting0} className={styles.toggleSetting}>
          {userProfile?.settings?.autoPlayWordTTS ? (
            <FontAwesomeIcon
              icon={faToggleOn}
              className={styles.toggleIcon__on}
            />
          ) : (
            <FontAwesomeIcon
              icon={faToggleOff}
              className={styles.toggleIcon__off}
            />
          )}
        </div>
      </li>
      <li className={styles.setting}>
        <p className={styles.settingTxt}>
          automatically play sentence TTS upon navigation
        </p>
        <div onClick={toggleSetting1} className={styles.toggleSetting}>
          {userProfile?.settings?.autoPlaySentenceTTS ? (
            <FontAwesomeIcon
              icon={faToggleOn}
              className={styles.toggleIcon__on}
            />
          ) : (
            <FontAwesomeIcon
              icon={faToggleOff}
              className={styles.toggleIcon__off}
            />
          )}
        </div>
      </li>
    </ul>
  );
};

export default SSVSettings;
