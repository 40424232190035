import React from "react";
import {
  useGetUserProfileQuery,
  useUpdateUserHasLoggedInMutation,
} from "../profile/profileApiSlice";
import { useSelector } from "react-redux";
import { selectBookById } from "./booksApiSlice";
import { Link } from "react-router-dom";

import styles from "./Lesson.module.css";

const Lesson = ({ bookId, chapter, coverSrc, progress, available }) => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");
  const shouldFlash = !userProfile?.hasLoggedIn;

  const [updateUserHasLoggedIn] = useUpdateUserHasLoggedInMutation();

  const handleUpdateUserHasLoggedIn = async () => {
    if (shouldFlash) {
      try {
        await updateUserHasLoggedIn({
          value: true,
        }).unwrap();
      } catch (err) {
        console.error("error updating use hasLoggedIn:", err);
      }
    }
  };

  const book = useSelector((state) => selectBookById(state, bookId));

  const progressNew = Number.isNaN(parseFloat(progress?.new))
    ? "--"
    : parseFloat(progress?.new).toFixed(0);

  const progresSeen = Number.isNaN(parseFloat(progress?.seen))
    ? "--"
    : parseFloat(progress?.seen).toFixed(0);

  const progressKnown = Number.isNaN(parseFloat(progress?.known))
    ? "--"
    : parseFloat(progress?.known).toFixed(0);

  return (
    <Link
      onClick={handleUpdateUserHasLoggedIn}
      to={`${available ? `/ssv/${bookId}/${chapter}` : ""} `}
      className={`${styles.link} ${
        shouldFlash && available ? "flash-animation" : ""
      } ${available ? "available" : "unavailable"}`}
    >
      <li>
        <figure className={styles.figure}>
          <div className={styles.lessonCoverContainer}>
            <img
              className={styles.lessonCover}
              src={coverSrc}
              alt="Lesson Cover"
              loading="lazy"
            ></img>
          </div>
          <figcaption className={styles.figcaption}>
            {/* <h3 className={styles.lessonHeader}>
              <span className={styles.bookTitle}>{book.title}</span> "Chapter{" "}
              {chapter}"
            </h3> */}
            <h3 className={styles.lessonHeader}>
              {book.title}
              {/* {" "} */}
              {/* <span className={styles.chapterTitle}>Chapter {chapter}</span> */}
            </h3>
            <p className={styles.chapterTitle}>Chapter {chapter}</p>
            <p className={styles.lessonAuthor}>{book.author}</p>
            {/* <p className={styles.chapterTitle}>Chapter {chapter}</p> */}
            <div className={styles.figcaption_vocabRow}>
              <img
                className={styles.figcaption_vocabFlag}
                src="/img/flags/Spanish.png"
                alt="Spanish flag"
              ></img>
              <div className={styles.percentagesRow}>
                <span
                  title="% of New Fragments"
                  className={styles.figcaption_vocab__new}
                >
                  {progressNew}%
                </span>
                <span
                  title="% of Seen Fragments"
                  className={styles.figcaption_vocab__seen}
                >
                  {progresSeen}%
                </span>
                <span
                  title="% of Known Fragments"
                  className={styles.figcaption_vocab__known}
                >
                  {progressKnown}%
                </span>
              </div>
            </div>
          </figcaption>
        </figure>
      </li>
    </Link>
  );
};

export default Lesson;
