import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";

import styles from "./ConstructionMsg.module.css";

const ConstructionMsg = () => {
  return (
    <>
      <div className={styles.container}>
        <FontAwesomeIcon icon={faScrewdriverWrench} className={styles.icon} />
      </div>
      <p className={styles.msg}>
        We're under construction.<br></br> More coming soon!
      </p>
    </>
  );
};

export default ConstructionMsg;
