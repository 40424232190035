import React from "react";
import useTitle from "../../../../../hooks/useTitle";

// import styles from "./Donate.module.css";

const Donate = () => {
  useTitle("Donate");

  const content = <h2>Donate</h2>;

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default Donate;
