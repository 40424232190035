const scrollAppToTop = () => {
  const appElement = document.getElementById("appLayout");
  if (appElement) {
    appElement.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

export default scrollAppToTop;
