// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCircleInfo,
//   faCircleXmark,
//   faChevronRight,
//   faChevronDown,
// } from "@fortawesome/free-solid-svg-icons";

// import styles from "./GrammarItem.module.css";

// const GrammarItem = ({
//   itemName,
//   itemDescription,
//   value,
//   valueDescription,
//   selected,
//   color,
// }) => {
//   const [showingValueDescription, setShowingDescription] = useState(false);
//   const [showingItemDescription, setShowingItemDescription] = useState(false);

//   useEffect(() => {
//     if (!showingValueDescription) {
//       setShowingItemDescription(false);
//     }
//   }, [showingValueDescription]);

//   return (
//     <div className={styles.container}>
//       <div
//         className={styles.itemRow}
//         onClick={() => setShowingDescription(!showingValueDescription)}
//       >
//         {/* <p className={selected ? styles.item__selected : styles.item}> */}
//         <p className={styles.item}>
//           <span className={color}>{itemName}</span>: {value}
//         </p>
//         <div className={styles.infoIcon}>
//           {showingValueDescription ? (
//             <FontAwesomeIcon icon={faChevronDown} />
//           ) : (
//             <FontAwesomeIcon icon={faChevronRight} />
//           )}
//         </div>
//       </div>
//       {showingValueDescription && (
//         <section className={styles.descriptionContainer}>
//           <div className={styles.itemInfoContainer}>
//             <p className={styles.itemName}>{itemName}</p>
//             {showingItemDescription ? (
//               <FontAwesomeIcon
//                 icon={faCircleXmark}
//                 onClick={() => setShowingItemDescription(false)}
//               />
//             ) : (
//               <FontAwesomeIcon
//                 icon={faCircleInfo}
//                 onClick={() => setShowingItemDescription(true)}
//               />
//             )}
//           </div>
//           {showingItemDescription && (
//             <p className={styles.itemDescription}>{itemDescription}</p>
//           )}
//           {showingValueDescription && (
//             <p className={styles.valueDescription}>
//               {valueDescription && (
//                 <>
//                   <span className={styles.valueSpan}>{value}</span> -{" "}
//                   {valueDescription}
//                 </>
//               )}
//             </p>
//           )}
//         </section>
//       )}
//     </div>
//   );
// };

// export default GrammarItem;

import React, { useState, useContext, useEffect } from "react";
import { TourContext } from "../../TourProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleXmark,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./GrammarItem.module.css";
import tourStyles from "../../Tour.module.css";

const GrammarItem = ({
  itemName,
  itemDescription,
  value,
  valueDescription,
  selected,
  color,
}) => {
  const { currentTask, setCurrentTask } = useContext(TourContext);

  const task8itemNames = ["part of speech", "pronoun type"];

  const [showingValueDescription, setShowingDescription] = useState(false);

  // reset state for task 9
  useEffect(() => {
    if (currentTask === 9 && itemName === "language") {
      setShowingDescription(false);
    }
  }, [setShowingDescription, currentTask]);

  // reset state for task 10
  useEffect(() => {
    if (currentTask === 10 && itemName === "language") {
      setShowingDescription(true);
    }
  }, [setShowingDescription, currentTask]);

  // reset state for task 11
  useEffect(() => {
    if (currentTask === 11 && itemName === "language") {
      setShowingDescription(true);
    }
  }, [setShowingDescription, currentTask]);

  return (
    <div
      className={`${styles.container}
    
    `}
    >
      {showingValueDescription ? (
        <>
          <section
            className={`${styles.descriptionContainer}
          ${
            currentTask === 10 && itemName === "language"
              ? `${tourStyles.taskFocusNoBorder__unclickable}`
              : ``
          }
          ${
            currentTask === 11 && itemName === "language"
              ? `${tourStyles.taskFocusNoBorder}`
              : ``
          }
          `}
          >
            <div className={styles.itemContainer}>
              <div className={styles.itemInfoContainer}>
                <p className={`${color} ${styles.itemName}`}>{itemName}: </p>
                <FontAwesomeIcon
                  className={`${styles.icon}
                  ${
                    currentTask === 11 && itemName === "language"
                      ? `${tourStyles.taskFocus}`
                      : ``
                  }
                  `}
                  icon={faChevronDown}
                  onClick={() => {
                    setShowingDescription(false);
                    if (currentTask === 11) {
                      setCurrentTask(12);
                    }
                  }}
                />
              </div>
              <p className={styles.itemDescription}>{itemDescription}</p>
            </div>
            {value && (
              <p className={styles.valueDescription}>
                <>
                  {valueDescription ? (
                    <>
                      <span className={styles.valueSpan}>{value}</span> -{" "}
                      {valueDescription}
                    </>
                  ) : (
                    <>
                      <span className={styles.valueSpan}>{value}</span>
                    </>
                  )}
                </>
              </p>
            )}
          </section>
        </>
      ) : (
        <div
          className={`${styles.itemRow}
          ${
            currentTask === 9 && itemName === "language"
              ? `${tourStyles.taskFocusNoBorder}`
              : ``
          }
          ${
            currentTask === 10 && itemName === "language"
              ? `${tourStyles.taskFocusNoBorder}`
              : ``
          }
          `}
          onClick={() => {
            setShowingDescription(true);
            if (currentTask === 9) {
              setCurrentTask(10);
            }
          }}
        >
          <p className={styles.item}>
            <span
              className={`${color}
            ${styles.itemName}
            ${
              currentTask === 8 && task8itemNames.includes(itemName)
                ? `${tourStyles.taskFocus__unclickable}`
                : ``
            }
            `}
            >
              {itemName}:{" "}
            </span>
            {value}
          </p>
          <div
            className={`${styles.infoIcon}
                  `}
          >
            {showingValueDescription ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GrammarItem;
