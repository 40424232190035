import React, { useState, useContext, useEffect, useCallback } from "react";
import { TourContext } from "../TourProvider";
import Nav from "../Nav/Nav";
import Target from "../Target/Target";
import Info from "../Info/Info";
import Toolbar from "../Toolbar/Toolbar";
import { SENTENCES, WORD_INSTANCES, VOCABULARIES } from "../data";

import styles from "./StepOne.module.css";
import tourStyles from "../Tour.module.css";

const StepOne = ({
  setTourActive,
  setShowingTourPopup,
  // setCurrentTourStep,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
}) => {
  const { currentTask, nextTask, prevTask } = useContext(TourContext);

  const [currentSentenceId, setCurrentSentenceId] = useState("1");
  const [totalSentences] = useState(2);
  const [selectedWord, setSelectedWord] = useState(null);
  const [selectedFragment, setSelectedFragment] = useState(null);
  const [translationVisible, setTranslationVisible] = useState({});
  const [showTranslationSection, setShowTranslationSection] = useState(false);
  const [showGrammarSection, setShowGrammarSection] = useState(true);
  const [knownCount, setKnownCount] = useState(null);

  // const [currentTask, setCurrentTask] = useState(0);

  // const nextTask = () => setCurrentTask((prev) => prev + 1);
  // const prevTask = () => setCurrentTask((prev) => prev - 1);

  const sentences = SENTENCES;

  const currentSentenceIndex =
    sentences?.entities[currentSentenceId]?.position - 1;

  const currentWordInstances = Object.values(WORD_INSTANCES.entities).filter(
    (obj) => obj.sentence === currentSentenceId
  );

  // const [vocabularies, setVocabularies] = useState(VOCABULARIES.entities);
  // use a deep copy
  const initVocabulary = JSON.parse(JSON.stringify(VOCABULARIES.entities));
  const [vocabularies, setVocabularies] = useState(initVocabulary);

  // const getVocabularyByFragmentId = (fragmentId) => {
  //   if (vocabularies) {
  //     return Object.values(vocabularies).find(
  //       (vocab) => vocab.fragment === fragmentId
  //     );
  //   }
  // };
  const getVocabularyByFragmentId = useCallback(
    (fragmentId) => {
      if (vocabularies) {
        return Object.values(vocabularies).find(
          (vocab) => vocab.fragment === fragmentId
        );
      }
    },
    [vocabularies] // Add dependencies here
  );

  const resetSelections = () => {
    setSelectedWord(null);
    setSelectedFragment(null);
  };

  const handleStateReset = () => {
    resetSelections();
    setTranslationVisible({});
    setVocabularies(initVocabulary);
  };

  const onNextSentenceClick = () => {
    if (
      currentSentenceIndex !== undefined &&
      currentSentenceIndex >= 0 &&
      currentSentenceIndex < sentences.ids.length - 1
    ) {
      const nextSentenceId = sentences.ids[currentSentenceIndex + 1];
      setCurrentSentenceId(nextSentenceId);
      resetSelections();
      setShowTranslationSection(false);
      // await handleSaveLastRead(nextSentenceId);
    }
  };

  const onPrevSentenceClick = () => {
    if (currentSentenceIndex !== undefined && currentSentenceIndex > 0) {
      const prevSentenceId = sentences.ids[currentSentenceIndex - 1];
      setCurrentSentenceId(prevSentenceId);
      resetSelections();
      // await handleSaveLastRead(prevSentenceId);
    }
  };

  const showTranslation = (wordInstanceId) => {
    setTranslationVisible((prev) => ({
      ...prev,
      [wordInstanceId]: true,
    }));
  };

  // use React state instead of RTK Query
  const toggleMarkAsKnown = () => {
    if (selectedFragment) {
      // get vocabulary state
      const vocabulary = getVocabularyByFragmentId(selectedFragment);
      // update known count
      switch (vocabulary.state) {
        case "known":
          setKnownCount(knownCount - 1);
          break;
        default:
          setKnownCount(knownCount + 1);
          break;
      }
      // update vocabulary state
      const newState = vocabulary?.state === "known" ? "seen" : "known";
      vocabularies[vocabulary._id].state = newState;
    }
  };

  // use React state instead of RTK Query
  const markAsSeen = async (wordId, fragmentId, vocabulary) => {
    if (!vocabulary?._id || vocabulary?.state === "new") {
      vocabulary.state = "seen";
    }
  };

  const handleFragmentSelection = (wordId, fragmentId, vocabulary) => {
    setSelectedFragment(fragmentId);
    setSelectedWord(wordId);
    showTranslation(wordId);
    markAsSeen(wordId, fragmentId, vocabulary);
  };

  const onNextFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // check no selection
    if (!selectedWord || !selectedFragment) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[0]?.stem?._id
      );
      // select first word stem
      handleFragmentSelection(
        currentWordInstances[0]?._id,
        currentWordInstances[0]?.stem?._id,
        vocabulary
      );
      return;
    }

    // check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // check if additional fragments
    if (
      selectedFragmentIdx >= 0 &&
      selectedFragmentIdx < fragments.length - 1
    ) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx + 1]
      );
      // select next fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx + 1], // next fragment
        vocabulary
      );
      return;
    }

    // check if additional words
    else if (selectedWordIdx < currentWordInstances.length - 1) {
      vocabulary = getVocabularyByFragmentId(
        currentWordInstances[selectedWordIdx + 1].stem._id
      );
      // select next word stem
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx + 1]._id, // next word
        currentWordInstances[selectedWordIdx + 1].stem._id, // stem
        vocabulary
      );
    }

    // if last word call onNextSentenceClick
    else {
      onNextSentenceClick();
    }
  };

  const onPrevFragmentClick = (currentWordInstances) => {
    let vocabulary;

    // check no selection
    if (!selectedWord || !selectedFragment) {
      // check prev sentence
      if (currentSentenceIndex > 0) {
        // go to prev sentence
        onPrevSentenceClick();
      }
      return;
    }

    // check selection
    const currentWord = currentWordInstances.find(
      (wi) => wi._id === selectedWord
    );
    if (!currentWord) return;

    // get word index
    const selectedWordIdx = currentWordInstances.findIndex(
      (wi) => wi._id === selectedWord
    );

    // get fragment index
    const fragments = ["stem", "ending1", "ending2", "ending3"]
      .map((fragment) => currentWord[fragment]?._id)
      .filter((id) => id);
    const selectedFragmentIdx = fragments.indexOf(selectedFragment);

    // check if prev fragments
    if (selectedFragmentIdx > 0) {
      vocabulary = getVocabularyByFragmentId(
        fragments[selectedFragmentIdx - 1]
      );
      // select prev fragment
      handleFragmentSelection(
        selectedWord,
        fragments[selectedFragmentIdx - 1], // prev fragment
        vocabulary
      );
      return;
    }

    // check if prev words
    else if (selectedWordIdx > 0) {
      // get prev word
      const prevWord = currentWordInstances[selectedWordIdx - 1];
      // get prev word fragments
      const prevWordFragments = ["stem", "ending1", "ending2", "ending3"]
        .map((fragment) => prevWord[fragment]?._id)
        .filter((id) => id);
      // get prev word last ending
      const prevWordLastFragment =
        prevWordFragments[prevWordFragments.length - 1];

      vocabulary = getVocabularyByFragmentId(prevWordLastFragment);
      // select prev word last ending
      handleFragmentSelection(
        currentWordInstances[selectedWordIdx - 1]._id, // prev word
        prevWordLastFragment,
        vocabulary
      );
    }

    // if first word call onPrevSentenceClick
    else {
      onPrevSentenceClick();
      // select last word last fragment?
    }
  };

  const toggleTranslationVisibility = () => {
    setTranslationVisible((prev) => ({
      ...prev,
      [selectedWord]: !prev[selectedWord],
    }));
  };

  const handleSeeTranslationClick = () => {
    toggleTranslationVisibility();
    // markAsSeen();
  };

  // useEffects which enable previous feature in tutorial

  // reset "est-" vocabulary  to "seen" at start of step 13
  // useEffect(() => {
  //   if (currentTask === 13) {
  //     console.log("inside useEffect");
  //     // get vocabulary state
  //     const vocabulary = getVocabularyByFragmentId(selectedFragment);
  //     console.log(`vocabulary.state: ${vocabulary.state}`);
  //     const resetState = "seen";
  //     console.log(
  //       `BEFORE UPDATE - vocabularies[vocabulary._id].state: ${
  //         vocabularies[vocabulary._id].state
  //       }`
  //     );
  //     // deep copy vocabularies
  //     const vocabularies_deepCopy = JSON.parse(JSON.stringify(vocabularies));
  //     vocabularies_deepCopy[vocabulary._id].state = resetState;
  //     setVocabularies(vocabularies_deepCopy);
  //     console.log(
  //       `AFTER UPDATE - vocabularies[vocabulary._id].state: ${
  //         vocabularies[vocabulary._id].state
  //       }`
  //     );
  //   }
  // }, [currentTask, vocabularies, getVocabularyByFragmentId, selectedFragment]);
  const [task13EffectRan, setTask13EffectRan] = useState(false);
  useEffect(() => {
    if (currentTask === 13 && !task13EffectRan) {
      console.log("inside useEffect");

      // Get vocabulary state
      const vocabulary = getVocabularyByFragmentId(selectedFragment);
      if (vocabulary) {
        console.log(`vocabulary.state: ${vocabulary.state}`);
        const resetState = "seen";
        console.log(
          `BEFORE UPDATE - vocabularies[vocabulary._id].state: ${
            vocabularies[vocabulary._id].state
          }`
        );

        // Deep copy vocabularies
        const vocabularies_deepCopy = JSON.parse(JSON.stringify(vocabularies));
        vocabularies_deepCopy[vocabulary._id].state = resetState;
        setVocabularies(vocabularies_deepCopy);
        setKnownCount(null);
        setTask13EffectRan(true);
        console.log(
          `AFTER UPDATE - vocabularies[vocabulary._id].state: ${
            vocabularies[vocabulary._id].state
          }`
        );
      } else {
        console.error(
          `No vocabulary found for fragment ID: ${selectedFragment}`
        );
      }
    }
  }, [currentTask, getVocabularyByFragmentId, selectedFragment]);

  // task 16
  useEffect(() => {
    if (currentTask === 16) {
      setSelectedFragment("est");
    }
  }, [currentTask]);

  // task 19
  useEffect(() => {
    if (currentTask === 19) {
      setCurrentSentenceId("1");
      setSelectedFragment("a");
    }
  }, [currentTask]);

  // task 21.1
  useEffect(() => {
    if (currentTask === 21.1) {
      setShowTranslationSection(false);
    }
  }, [currentTask]);
  // end useEffects for previous feature in tutorial

  const focusOverlayTasks = [5, 6, 13, 14, 15, 16, 17];

  return (
    <>
      <div className={styles.grid}>
        <Nav
          setTourActive={setTourActive}
          setShowingTourPopup={setShowingTourPopup}
          currentSentenceId={currentSentenceId}
          SENTENCES={SENTENCES}
          totalSentences={totalSentences}
          onNextSentenceClick={onNextSentenceClick}
          onPrevSentenceClick={onPrevSentenceClick}
          knownCount={knownCount}
          // setShowingSSVSettings={setShowingSSVSettings}
          // setShowingSSVHelp={setShowingSSVHelp}
          handleShowSSVSettingsClick={handleShowSSVSettingsClick}
          handleShowSSVHelpClick={handleShowSSVHelpClick}
        />
        <Target
          // currentTask={currentTask}
          // setCurrentTask={setCurrentTask}
          handleStateReset={handleStateReset}
          currentWordInstances={currentWordInstances}
          selectedWord={selectedWord}
          selectedFragment={selectedFragment}
          translationVisible={translationVisible}
          handleFragmentSelection={handleFragmentSelection}
          getVocabularyByFragmentId={getVocabularyByFragmentId}
          setTask13EffectRan={setTask13EffectRan}
        />
        <Info
          SENTENCES={SENTENCES}
          currentSentenceId={currentSentenceId}
          currentWordInstances={currentWordInstances}
          selectedWord={selectedWord}
          showGrammarSection={showGrammarSection}
          showTranslationSection={showTranslationSection}
          setShowTranslationSection={setShowTranslationSection}
          setShowGrammarSection={setShowGrammarSection}
          getVocabularyByFragmentId={getVocabularyByFragmentId}
        />
      </div>
      <div
        className={`
      ${
        focusOverlayTasks.includes(currentTask)
          ? `${styles.focusOverlay}`
          : `${styles.overlay}`
      }   
      
      `}
      >
        <Toolbar
          currentWordInstances={currentWordInstances}
          selectedFragment={selectedFragment}
          selectedWord={selectedWord}
          translationVisible={translationVisible}
          handleSeeTranslationClick={handleSeeTranslationClick}
          onNextFragmentClick={onNextFragmentClick}
          onPrevFragmentClick={onPrevFragmentClick}
          toggleMarkAsKnown={toggleMarkAsKnown}
          getVocabularyByFragmentId={getVocabularyByFragmentId}
        />
      </div>
      <div className={tourStyles.darkOverlay}></div>
    </>
  );
};

export default StepOne;

// user will read explainations & complete tasks sequentially

// SEQUENCE DRAFT

/** explain:
  - Target sentence area - task 0 DONE
  - fragments: stems & endings
  - state: fragment colors
  */

// encourage the user to turn up the volume on their device to hear TTS

// task1: select first fragment by tapping it in Target

/** explain:
  - selection: bordered fragment & selection text in Toolbar
  - state change to "seen",
  - word TTS
  - word translation
  - word morphology
  */

// task2: mark currently selected fragment as "known" by tapping checkmark in Toolbar

/** explain:
  - state changed to "known",
  - known fragment count increased
  - Toolbar checkmark automatically changed to undo arrow since the currently selected fragment is "known"
  */

// task3: revert fragment back to "seen" by tapping the undo-arrow icon in the Toolbar

/** explain:
  - state changed back to "seen",
  - known fragment count decreased
  - Toolbar undo arrow automatically changed back to check-mark icon since the currently selected fragment is "seen"
  */

// task4: replay word TTS by tapping the volume-high icon in the Toolbar

/** explain:
  - default setting is to automatically play word TTS upon selection
  - settings can be changed
  */

// task5: disable word translation visibility by tapping the eye-slash icon in the toolbar

/** explain:
  - the eye-slash icon automatically changed to eye icon since the currently selected word is not showing the word translation
  - enable the visibility of word translation by tapping the eye icon
  */

// task6: enable word translation visibility by tapping the eye icon in the Toolbar

// StepTwo?
// task5: open the Settings drawer by tapping the gear icon

/** explain: 
  - the Settings drawer can be closed by tapping the circle-xmark icon OR
  - the gear icon toggles the display of the Settings drawer, so you can also close the Settings drawer by tapping the gear icon again
  */

// task6: close the Settings drawer using either method
