import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../features/auth/AuthContext";
import LaunchNewsletter from "./LaunchNewsletter";

import styles from "./HomeV2.module.css";

const Home = () => {
  // useTitle("Iter Lexici") fix?
  const { auth } = useContext(AuthContext);

  return (
    <main className="page-wrapper">
      <section className={styles.hero}>
        <div className={styles.dqEngraving}></div>
        <div className={styles.heroCol}>
          <h1 className={styles.heroHeading}>
            Iter Lexici
            {/* Immerse <br></br>Yourself */}
          </h1>
          <h2 className={styles.heroH2}>Acquire Language</h2>
        </div>
        {!auth && (
          <Link to="/sign-up" className={styles.cta}>
            <p className={styles.heroP}>
              Try for <span className={styles.free}>Free</span>
            </p>
          </Link>
        )}
      </section>
      <LaunchNewsletter />
    </main>
  );
};

export default Home;
