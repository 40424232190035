const scrollToTop = (ref) => {
  if (ref.current) {
    ref.current.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
};

export default scrollToTop;
