import React from "react";
import { Link, Outlet } from "react-router-dom";
import useTitle from "../../hooks/useTitle";

import styles from "./AdminLayout.module.css";

const AdminLayout = () => {
  useTitle("Admin");

  return (
    <main className="page-wrapper">
      <div className={styles.header}>
        <Link to="/profile" className={styles.headerLink}>
          /profile
        </Link>
        <h1>admin</h1>
        <div className={styles.headerSpacer}></div>
      </div>
      <Outlet />
    </main>
  );
};

export default AdminLayout;
