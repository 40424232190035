import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const sentencesAdapter = createEntityAdapter({});

const initialState = sentencesAdapter.getInitialState();

export const sentencesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSentencesInBook: builder.query({
      query: ({ bookId, chapter }) => ({
        url: `/sentences/${bookId}/${chapter}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedSentences = responseData.map((sentence) => {
          sentence.id = sentence._id;
          return sentence;
        });
        return sentencesAdapter.setAll(initialState, loadedSentences);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Sentence", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Sentence", id })),
          ];
        } else return [{ type: "Sentence", id: "LIST" }];
      },
    }),
  }),
});

// RTK Query automatically creates the following hooks
export const { useGetAllSentencesInBookQuery } = sentencesApiSlice;

// query result object
// OLD
// export const selectAllSentencesInBookResult =
//   sentencesApiSlice.endpoints.getAllSentencesInBook.select();
// NEW
// dynamic selector that accepts bookId and retrieves query results for that specific book
export const selectAllSentencesInBookResult = (bookId, chapter) =>
  sentencesApiSlice.endpoints.getAllSentencesInBook.select({ bookId, chapter });

// memoized selector
// OLD
// const selectAllSentencesInBookData = createSelector(
//   selectAllSentencesInBookResult,
//   (sentencesResult) => sentencesResult.data // normalized state object w/ ids & entities
// )

// BEST PRACTICE
// export const selectSentenceById = createSelector(
//   // 1st input selector
//   (state, bookId) =>
//     selectAllSentencesInBookResult(bookId)(state)?.data?.entities, // directly use entities object
//   // 2nd input selector
//   (_state, _bookId, sentenceId) => sentenceId,
//   (entities, sentenceId) => entities[sentenceId] // Directly access the sentence using sentenceId
// );

// passing chapter
export const selectSentenceById = createSelector(
  // 1st input selector: state and the composite key ({ bookId, chapter })
  (state, bookId, chapter) =>
    selectAllSentencesInBookResult(bookId, chapter)(state)?.data?.entities,

  // 2nd input selector: sentenceId
  (_state, _bookId, _chapter, sentenceId) => sentenceId,

  // Output selector: return the sentence entity by its ID
  (entities, sentenceId) => (entities ? entities[sentenceId] : undefined)
);

// POOR PRACTICE
// export const selectSentenceById = createSelector(
//   // 1st input selector is the problem
//   (state, bookId) => selectAllSentencesInBookResult(bookId)(state), // depends on the entire query result object, potentially reacting to any changes in this object, even if the actual `entities` hadn't changed
//   // 2nd input selector
//   (_state, _bookId, sentenceId) => sentenceId, // capturing `sentenceId` for usage in the last argument
//   (sentencesResult, sentenceId) => sentencesResult.data?.entities[sentenceId] // accessing specific sentence
// );

// select all sentences
// const selectAllSentencesInBookData = createSelector(
//   selectAllSentencesInBookResult,
//   (sentencesResult) => sentencesResult.data // normalized state object w/ ids & entities
// );
