import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const CurrentSelectionHelp = () => {
  const [showingHelp, setShowingHelp] = useState(false);

  const handleClick = async () => {
    setShowingHelp(!showingHelp);
    // await handleUpdateUserHasLoggedIn();
  };

  return (
    <li className={`${styles.helpList__colItem} `} onClick={handleClick}>
      <div
        className={styles.h3Row}
        // onClick={() => setshowingHelp(!showingHelp)}
      >
        {showingHelp ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Current Selection</h3>
      </div>

      {showingHelp && (
        <div
          className={`${styles.currentSelectionHelp} ${styles.bottomPadding}`}
        >
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <ul className={styles.bulletedList}>
                <li>
                  the currently selected fragment appears bordered in the target
                  sentence & in the toolbar
                </li>
                <li>
                  the currently selected word contains the currenlty selected
                  fragment and appears with a lighter background color
                </li>
                <li>
                  the currently selected sentence is the only one you can see at
                  any given time
                </li>
              </ul>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default CurrentSelectionHelp;
