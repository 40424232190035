import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import Form from "./Form";
import { useGetUserProfileQuery } from "../../profile/profileApiSlice";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import ErrMsg from "../../../components/shared/ErrMsg/ErrMsg";

import styles from "./ResetPassword.module.css";

const ResetPassword = () => {
  const { isLoading, isResettingPassword, auth } = useContext(AuthContext);
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  console.log(`isResettingPassword: ${isResettingPassword}`);
  console.log(`auth: ${auth}`);
  console.log(`userProfile?.inboxToken: ${userProfile?.inboxToken}`);

  let content;

  if (isLoading) {
    content = <LoadMsg msg={"loading..."} size={"2x"} />;
  } else if (auth && isResettingPassword) {
    content = <Form />;
  } else {
    content = (
      <ErrMsg
        msgList={["Error resetting password"]}
        size={"2x"}
        additionalComponent={
          <p>
            Return to{" "}
            <Link to="/forgot-password" className={styles.return}>
              forgot password
            </Link>
          </p>
        }
      />
    );
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default ResetPassword;
