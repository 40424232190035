import React from "react";
import useTitle from "../../../../../hooks/useTitle";

// import styles from "./About.module.css";

const About = () => {
  useTitle("About");

  const content = <h2>About</h2>;

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default About;
