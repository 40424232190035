import toCamelCase from "../../../../utils/toCamelCase";

export const GRAMMAR_CONTENT = (selectedWordInstance) => {
  const posArr = selectedWordInstance?.pos.split(",");
  const posString = toCamelCase(posArr[0]);
  // console.log(`posString: ${posString}`);

  return [
    {
      item: "language",
      itemName: "language",
      itemDescription: "The language which the word belongs to",
      value: selectedWordInstance?.word?.language?.name,
      valueDescription:
        LANGUAGE_VALUES[
          toCamelCase(selectedWordInstance?.word?.language?.name)
        ],
    },
    {
      item: "modernEquivalent",
      itemName: "modern equivalent",
      itemDescription: "The modern language's corresponding word",
      value: selectedWordInstance?.modernEquivalent,
      valueDescription: null,
    },
    {
      item: "expectedWord",
      itemName: "expected word",
      itemDescription: "How the word would read were it not irregular",
      value: selectedWordInstance?.expectedWord,
      valueDescription: null,
    },
    {
      item: "lemma",
      itemName: "lemma",
      itemDescription: "A word's base form",
      value: selectedWordInstance?.word?.lemma,
      valueDescription: null,
    },
    {
      item: "pos",
      itemName: "part of speech",
      itemDescription: "Indicates a word's function",
      value: posArr[0],
      valueDescription: POS_VALUES[posString],
    },
    {
      item: "verbType",
      itemName: "verb type",
      itemDescription: "Determines which set of endings a verb will take",
      value: selectedWordInstance?.verbType,
      valueDescription: VERBTYPE_VALUES[
        selectedWordInstance?.verbType
      ]?.replace(/-/g, ""),
    },
    {
      item: "pronounType",
      itemName: "pronoun type",
      itemDescription:
        "A sub-category of pronouns indicating more specifically their usage in a sentence",
      value: selectedWordInstance?.pronounType,
      valueDescription:
        PRONOUNTYPE_VALUES[toCamelCase(selectedWordInstance?.pronounType)],
    },
    {
      item: "determinerType",
      itemName: "determiner type",
      itemDescription: "Determines which set of endings a determiner will take",
      value: selectedWordInstance?.determinerType,
      // valueDescription: DETERMINERTYPE_VALUES[
      //   selectedWordInstance?.determinerType
      // ]?.replace(/-/g, ""),
      // TODO add DETERMINERTYPE_VALUES
      valueDescription: null,
    },
    {
      item: "form",
      itemName: "form",
      itemDescription: "The broadest category of verb",
      value: selectedWordInstance?.form,
      valueDescription: FORM_VALUES[toCamelCase(selectedWordInstance?.form)],
    },
    {
      item: "mood",
      itemName: "mood",
      itemDescription: "The speaker's attitude in the use of the verb",
      value: selectedWordInstance?.mood,
      valueDescription: MOOD_VALUES[toCamelCase(selectedWordInstance?.mood)],
    },
    {
      item: "tense",
      itemName: "tense",
      itemDescription: "Indicates the time aspect of the verb",
      value: selectedWordInstance?.tense,
      valueDescription: TENSE_VALUES[toCamelCase(selectedWordInstance?.tense)],
    },
    {
      item: "person",
      itemName: "person",
      itemDescription: "Indicates the perspective of the subject",
      value: selectedWordInstance?.person,
      valueDescription:
        PERSON_VALUES[toCamelCase(selectedWordInstance?.person)],
    },
    {
      item: "number",
      itemName: "number",
      itemDescription: "Indicates the numerical composition",
      value: selectedWordInstance?.number,
      valueDescription:
        NUMBER_VALUES[toCamelCase(selectedWordInstance?.number)],
    },
    {
      item: "gender",
      itemName: "gender",
      itemDescription:
        "A classification of words which affects agreement with others",
      value: selectedWordInstance?.gender,
      valueDescription:
        GENDER_VALUES[toCamelCase(selectedWordInstance?.gender)],
    },
    {
      item: "pronounFunction",
      itemName: "pronoun function",
      // TODO: rewrite itemDescription
      itemDescription: "The function of the pronoun",
      value: selectedWordInstance?.pronounFunction,
      // valueDescription:
      //   FUNCTION_VALUES[toCamelCase(selectedWordInstance?.function)],
      // TODO: include this? if so, write FUNCTION_VALUES
      valueDescription: null,
    },
    {
      item: "clitic1Function",
      itemName: "first clitic function",
      itemDescription: "The 1st clitic's role as it relates to the verb",
      value: selectedWordInstance?.clitic1Function,
      valueDescription:
        CLITIC1FUNCTION_VALUES[
          toCamelCase(selectedWordInstance?.clitic1Function)
        ],
    },
    {
      item: "clitic1Gender",
      itemName: "first clitic gender",
      itemDescription:
        "A classification of the noun represented by the 1st clitic which affects agreement with other words",
      value: selectedWordInstance?.clitic1Gender,
      valueDescription:
        CLITIC1GENDER_VALUES[toCamelCase(selectedWordInstance?.clitic1Gender)],
    },
    {
      item: "clitic1Number",
      itemName: "first clitic number",
      itemDescription:
        "The numerical composition of the noun represented by the 1st clitic",
      value: selectedWordInstance?.clitic1Number,
      valueDescription:
        CLITIC1NUMBER_VALUES[toCamelCase(selectedWordInstance?.clitic1Number)],
    },
    {
      item: "clitic1Person",
      itemName: "first clitic person",
      itemDescription:
        "The perspective of the noun represented by the 1st clitic",
      value: selectedWordInstance?.clitic1Person,
      valueDescription:
        CLITIC1PERSON_VALUES[toCamelCase(selectedWordInstance?.clitic1Person)],
    },
    {
      item: "clitic2Function",
      itemName: "second clitic function",
      itemDescription: "The 2nd clitic's role as it relates to the verb",
      value: selectedWordInstance?.clitic2Function,
      valueDescription:
        CLITIC2FUNCTION_VALUES[
          toCamelCase(selectedWordInstance?.clitic2Function)
        ],
    },
    {
      item: "clitic2Gender",
      itemName: "second clitic gender",
      itemDescription:
        "A classification of the noun represented by the 2nd clitic which affects agreement with other words",
      value: selectedWordInstance?.clitic2Gender,
      valueDescription:
        CLITIC2GENDER_VALUES[toCamelCase(selectedWordInstance?.clitic2Gender)],
    },
    {
      item: "clitic2Number",
      itemName: "second clitic number",
      itemDescription:
        "The numerical composition of the noun represented by the 2nd clitic",
      value: selectedWordInstance?.clitic2Number,
      valueDescription:
        CLITIC2NUMBER_VALUES[toCamelCase(selectedWordInstance?.clitic2Number)],
    },
    {
      item: "clitic2Person",
      itemName: "second clitic person",
      itemDescription:
        "The perspective of the noun represented by the 2nd clitic",
      value: selectedWordInstance?.clitic2Person,
      valueDescription:
        CLITIC2PERSON_VALUES[toCamelCase(selectedWordInstance?.clitic2Person)],
    },
  ].filter((obj) => obj.value);
};
const POS_VALUES = {
  noun: "A person, place or thing",
  adjective: "Describes or modifies a noun",
  pronoun: "Holds the place of a noun",
  adposition:
    "Show direction, location, time, manner, cause, purpose or possession",
  verb: "Describes an action, occurence or state",
  coordinatingConjunction:
    "Connects words, phrases or clauses of equal importance",
  subordinatingConjunction:
    "Connects an independent clause to a dependent clause",
  adverb: "Describes or modifies a noun",
  determiner: "Introduces a noun",
  abbreviation: "A shortened form of a word or phrase",
  interjection: "Expresses a sudden emotion or reaction",
  particle: "A small function word which does not change form",
  properNoun: "A specific name given to a person, place or thing",
};

const VERBTYPE_VALUES = {
  ar: "The infinitive (lemma) of the verb ends in -ar",
  er: "The infinitive (lemma) of the verb ends in -er",
  ir: "The infinitive (lemma) of the verb ends in -ir",
};

const FORM_VALUES = {
  infinitive: "The verb's base form",
  finite: "Inflected to show tense, person, number and mood",
  pastParticiple: "Used with an auxiliary verb to indicate a completed action",
  presentParticiple: "Indicates an ongoing action",
};

const MOOD_VALUES = {
  indicative:
    "Used to make factual statements, ask questions, or express opinions that are believed to be true",
  subjunctive:
    "Used to express wishes, hypothetical situations, demands, or actions that are contrary to fact",
  imperative: "Used to give commands, make requests, or offer invitations",
};

const TENSE_VALUES = {
  present:
    "Used to describe actions or states that are currently happening, habitual actions, or general truths",
  preterite:
    "Used to describe actions or events that were completed in the past",
  imperfect:
    "Used to describe ongoing or habitual actions, states, or conditions in the past without specifying a definite beginning or end",
  future: "Used to describe actions or events that will happen at a later time",
  conditional:
    "Used to describe actions or events that would happen under certain conditions or hypothetical situations",
};

const PERSON_VALUES = {
  first: "Indicates a self-reference by the speaker",
  second: "Indicates a reference to the addressee of the speaker",
  third:
    "Indicates a reference to someone or something which is neither the speaker nor the addressee",
};

const NUMBER_VALUES = {
  singular: "Indicates a composition of one",
  plural: "Indicates a composition of more than one",
};

const GENDER_VALUES = {
  masculine:
    "Refers to a category of words typically, but not always, associated with male entities and qualities",
  feminine:
    "Refers to a category of words typically, but not always, associated with female entities and qualities",
  neuter: "Refers to a noun of unknown gender",
};

const CLITIC1GENDER_VALUES = {
  masculine:
    "Refers to a category of words typically, but not always, associated with male entities and qualities",
  feminine:
    "Refers to a category of words typically, but not always, associated with female entities and qualities",
};

const CLITIC1FUNCTION_VALUES = {
  directObject: "Indicates the recipient of the action",
  indirectObject: "Indicates the indirect recipient of the action",
  reflexive: "Indicates a subject acting upon itself",
};

const CLITIC1NUMBER_VALUES = {
  singular: "Indicates a composition of one",
  plural: "Indicates a composition of more than one",
};

const CLITIC1PERSON_VALUES = {
  first: "Indicates a self-reference by the speaker",
  second: "Indicates a reference to the addressee of the speaker",
  third:
    "Indicates a reference to someone or something which is neither the speaker nor the addressee",
};

const CLITIC2GENDER_VALUES = {
  masculine:
    "Refers to a category of words typically, but not always, associated with male entities and qualities",
  feminine:
    "Refers to a category of words typically, but not always, associated with female entities and qualities",
};

const CLITIC2FUNCTION_VALUES = {
  directObject: "Indicates the recipient of the action",
  indirectObject: "Indicates the indirect recipient of the action",
  reflexive: "Indicates a subject acting upon itself",
};

const CLITIC2NUMBER_VALUES = {
  singular: "Indicates a composition of one",
  plural: "Indicates a composition of more than one",
};

const CLITIC2PERSON_VALUES = {
  first: "Indicates a self-reference by the speaker",
  second: "Indicates a reference to the addressee of the speaker",
  third:
    "Indicates a reference to someone or something which is neither the speaker nor the addressee",
};

const LANGUAGE_VALUES = {
  spanish:
    "The modern form of Spanish which begins from the late 15th century and is still in use today",
  oldSpanish:
    "The predecessor to modern Spanish which begins from the 9th century and lasts until the 15th",
  latin:
    "An ancient language of the Roman Empire, used from around the 6th century BC until the fall of the Roman Empire in the 5th century AD",
};

const PRONOUNTYPE_VALUES = {
  personal: "Refers to a specific person or thing",
  impersonal: "Refers to general or unspecified subjects",
  possessive: "Shows ownership or possession",
  demonstrative: "Points to specific things",
  interrogative: "Used to ask questions",
  relative: "Introduces relative clauses",
  indefinite: "Refers to nonspecific things",
  reciprocal: "Indicates a mutual action or relationship",
  quantifier: "Expresses quantities",
};

// const PRONOUNFUNCTION_VALUES = {
//   subject: "Indicates who or what performs the action",
//   directObject: "Indicates the recipient of the action",
//   indirectObject: "Indicates the indirect recipient of the action",
//   reflexive: "Indicates a subject acting upon itself",
// };
