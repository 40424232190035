// import React, { useEffect, useCallback, useRef } from "react";
// import { useSelector } from "react-redux";
// import { useGetUserProfileQuery } from "../../profile/profileApiSlice";
// import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faChevronLeft,
//   faVolumeHigh,
//   faVolumeXmark,
//   faEye,
//   faEyeSlash,
//   faCheck,
//   faRotateLeft,
//   faChevronRight,
// } from "@fortawesome/free-solid-svg-icons";

// import styles from "./Toolbar.module.css";

// const Toolbar = ({
//   bookId,
//   chapter,
//   currentSentenceId,
//   // currentSentenceIndex,
//   // totalSentences,
//   selectedWord,
//   // setSelectedWord,
//   selectedFragment,
//   setSelectedFragment,
//   // fragmentState,
//   // setFragmentState,
//   translationVisible,
//   handleSeeTranslationClick,
//   onNextFragmentClick,
//   onPrevFragmentClick,
//   toggleMarkAsKnown,
//   getVocabularyByFragmentId,
// }) => {
//   const { data: userProfile } = useGetUserProfileQuery("userProfile");

//   const vocabulary = getVocabularyByFragmentId(selectedFragment);

//   const currentWordInstances = useSelector((state) =>
//     selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
//   );

//   const selectedWordInstance = currentWordInstances.find(
//     (wi) => wi._id === selectedWord
//   );

//   const audioRef = useRef(null);
//   // initialize only once
//   if (!audioRef.current) {
//     audioRef.current = new Audio();
//   }

//   // previous key mapping (left arrow)
//   useEffect(() => {
//     function handleKeyDown(event) {
//       if (event.key === "ArrowLeft") {
//         event.preventDefault();
//         event.stopPropagation();
//         onPrevFragmentClick(currentWordInstances);
//       }
//     }

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentWordInstances, onPrevFragmentClick]);

//   // memoized
//   // TODO: rename me
//   const onFragmentTTSClick = useCallback(() => {
//     if (!audioRef.current.paused) {
//       audioRef.current.pause();
//       audioRef.current.currentTime = 0;
//     }

//     const word = selectedWordInstance?.word?.text
//       .toLowerCase()
//       .replace(/[^a-záéíóúñ]/g, "");
//     if (!word) return;

//     audioRef.current.src = `/spanish-words/${word}.wav`;

//     audioRef.current
//       .play()
//       .catch((error) => console.error("Error playing the audio file:", error));
//   }, [selectedWordInstance]);

//   // setting0: autoPlayWordTTS
//   useEffect(() => {
//     if (userProfile?.settings?.autoPlayWordTTS) {
//       onFragmentTTSClick();
//     }
//   }, [
//     selectedWord,
//     onFragmentTTSClick,
//     userProfile?.settings?.autoPlayWordTTS,
//   ]);

//   // TTS key mapping (spacebar)
//   useEffect(() => {
//     const handleKeyDown = (event) => {
//       if (event.key === " " || event.key === "Spacebar") {
//         event.preventDefault();
//         event.stopPropagation();
//         onFragmentTTSClick();
//       }
//     };

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [onFragmentTTSClick]); // onFragmentTTSClick is stable now

//   // word translation key mapping (T)
//   useEffect(() => {
//     function handleKeyDown(event) {
//       if (event.key === "t") {
//         event.preventDefault();
//         event.stopPropagation();
//         handleSeeTranslationClick();
//       }
//     }

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentWordInstances, handleSeeTranslationClick]);

//   // mark as known key mapping (K)
//   useEffect(() => {
//     function handleKeyDown(event) {
//       if (event.key === "k") {
//         event.preventDefault();
//         event.stopPropagation();
//         toggleMarkAsKnown();
//       }
//     }

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentWordInstances, toggleMarkAsKnown]);

//   // next key mapping (right arrow)
//   useEffect(() => {
//     function handleKeyDown(event) {
//       if (event.key === "ArrowRight") {
//         event.preventDefault();
//         event.stopPropagation();
//         onNextFragmentClick(currentWordInstances);
//       }
//     }

//     document.addEventListener("keydown", handleKeyDown);

//     return () => {
//       document.removeEventListener("keydown", handleKeyDown);
//     };
//   }, [currentWordInstances, onNextFragmentClick]);

//   // selectionText
//   const selectionText = () => {
//     let selectionText = "--";

//     if (selectedFragment) {
//       const wordText = selectedWordInstance?.text;

//       // check stem
//       if (selectedWordInstance?.stem?._id === selectedFragment) {
//         const stemText = selectedWordInstance?.stem?.text;
//         selectionText = stemText;
//         // check dash
//         if (wordText.length > stemText.length) {
//           selectionText = selectionText + "-";
//         }
//       }

//       // check ending
//       else {
//         let endingText = "";

//         if (selectedWordInstance?.ending1?._id === selectedFragment) {
//           endingText = selectedWordInstance?.ending1?.text;
//         } else if (selectedWordInstance?.ending2?._id === selectedFragment) {
//           endingText = selectedWordInstance?.ending2?.text;
//         } else if (selectedWordInstance?.ending3?._id === selectedFragment) {
//           endingText = selectedWordInstance?.ending3?.text;
//         }

//         selectionText = endingText;

//         // check dash
//         if (wordText.length > endingText.length) {
//           selectionText = "-" + selectionText;
//         }
//       }
//     }

//     return selectionText;
//   };

//   let content;

//   content = (
//     <div className={styles.container}>
//       <div className={styles.colorPatch}> </div>
//       <div className={styles.positionToolbar}>
//         {/* <div className={styles.colorPatch}> </div> */}
//         <div className={styles.selectionContainer}>
//           <p className={styles.selectionText}>{selectionText()}</p>
//         </div>
//         <div className={styles.toolbarContainer}>
//           {/* previous button */}
//           <button
//             onClick={() => onPrevFragmentClick(currentWordInstances)}
//             type="button"
//             className={styles.button}
//             title="Previous - Left Arrow Key"
//           >
//             <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
//           </button>

//           {/* TTS button */}
//           <button
//             onClick={() => onFragmentTTSClick()}
//             type="button"
//             className={
//               selectedFragment
//                 ? `${styles.button}`
//                 : `${styles.button__disabled}`
//             }
//             title={
//               selectedFragment ? "Play Word Text to Speech - Spacebar" : ""
//             }
//           >
//             {selectedFragment ? (
//               <FontAwesomeIcon className={styles.icon} icon={faVolumeHigh} />
//             ) : (
//               <FontAwesomeIcon className={styles.icon} icon={faVolumeXmark} />
//             )}
//           </button>

//           {/* toggle translation button */}
//           <button
//             onClick={handleSeeTranslationClick}
//             type="button"
//             className={
//               selectedFragment
//                 ? `${styles.button}`
//                 : `${styles.button__disabled}`
//             }
//             title={
//               translationVisible[selectedWord]
//                 ? 'Hide Word Translation - "T" Key'
//                 : 'Show Word Translation - "T" Key'
//             }
//           >
//             {!selectedFragment ? (
//               <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
//             ) : translationVisible[selectedWord] ? (
//               <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
//             ) : (
//               <FontAwesomeIcon className={styles.icon} icon={faEye} />
//             )}
//           </button>

//           {/* toggle mark as known button */}
//           <button
//             onClick={toggleMarkAsKnown}
//             type="button"
//             className={
//               selectedFragment
//                 ? `${styles.button}`
//                 : `${styles.button__disabled}`
//             }
//             title={
//               vocabulary?.state !== "known"
//                 ? 'Mark Fragment as Known - "K" Key'
//                 : 'Revert Fragment to Seen - "K" Key'
//             }
//           >
//             {!selectedFragment ? (
//               <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
//             ) : vocabulary?.state !== "known" ? (
//               <FontAwesomeIcon className={styles.icon} icon={faCheck} />
//             ) : (
//               <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
//             )}
//           </button>

//           {/* next button */}
//           <button
//             onClick={() => onNextFragmentClick(currentWordInstances)}
//             type="button"
//             className={styles.button}
//             title="Next - Right Arrow Key"
//           >
//             <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
//           </button>
//         </div>
//       </div>
//     </div>
//   );

//   return content;
// };

// export default Toolbar;

import React, { useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import { useGetUserProfileQuery } from "../../profile/profileApiSlice";
import { selectWordInstancesBySentenceId } from "../wordInstancesApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faVolumeHigh,
  faVolumeXmark,
  faEye,
  faEyeSlash,
  faCheck,
  faRotateLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Toolbar.module.css";

const Toolbar = ({
  bookId,
  chapter,
  currentSentenceId,
  selectedWord,
  selectedFragment,
  setSelectedFragment,
  translationVisible,
  handleSeeTranslationClick,
  onNextFragmentClick,
  onPrevFragmentClick,
  toggleMarkAsKnown,
  getVocabularyByFragmentId,
}) => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const vocabulary = getVocabularyByFragmentId(selectedFragment);
  const currentWordInstances = useSelector((state) =>
    selectWordInstancesBySentenceId(state, bookId, chapter, currentSentenceId)
  );
  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  const containerRef = useRef(null); // Ref for the container

  // Initialize audioRef only once
  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio();
    }
  }, []);

  // Force repaint
  // const forceRepaint = (element) => {
  //   if (element) {
  //     element.style.display = 'none';
  //     element.offsetHeight; // Trigger reflow
  //     element.style.display = '';
  //   }
  // };
  // const forceRepaint = (element) => {
  //   if (element) {
  //     // Force reflow and repaint by using requestAnimationFrame
  //     requestAnimationFrame(() => {
  //       element.style.display = "none";
  //       element.offsetHeight; // Trigger reflow
  //       element.style.display = "";
  //     });
  //   }
  // };
  const forceRepaint = (element) => {
    if (element) {
      // Force reflow and repaint
      requestAnimationFrame(() => {
        element.style.display = "none";
        element.getBoundingClientRect(); // Trigger reflow
        element.style.display = "";
      });
    }
  };

  // Call forceRepaint when relevant dependencies change
  useEffect(() => {
    forceRepaint(containerRef.current);
  }, [
    selectedWord,
    selectedFragment,
    currentWordInstances,
    translationVisible,
    userProfile?.settings?.autoPlayWordTTS,
  ]);

  const onFragmentTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    const word = selectedWordInstance?.word?.text
      .toLowerCase()
      .replace(/[^a-záéíóúñ]/g, "");
    if (!word) return;

    audioRef.current.src = `/spanish-words/${word}.wav`;

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [selectedWordInstance]);

  useEffect(() => {
    if (userProfile?.settings?.autoPlayWordTTS) {
      onFragmentTTSClick();
    }
  }, [
    selectedWord,
    onFragmentTTSClick,
    userProfile?.settings?.autoPlayWordTTS,
  ]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        event.preventDefault();
        event.stopPropagation();
        onPrevFragmentClick(currentWordInstances);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, onPrevFragmentClick]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " || event.key === "Spacebar") {
        event.preventDefault();
        event.stopPropagation();
        onFragmentTTSClick();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onFragmentTTSClick]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "t") {
        event.preventDefault();
        event.stopPropagation();
        handleSeeTranslationClick();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, handleSeeTranslationClick]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "k") {
        event.preventDefault();
        event.stopPropagation();
        toggleMarkAsKnown();
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, toggleMarkAsKnown]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "ArrowRight") {
        event.preventDefault();
        event.stopPropagation();
        onNextFragmentClick(currentWordInstances);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentWordInstances, onNextFragmentClick]);

  const selectionText = () => {
    let selectionText = "--";

    if (selectedFragment) {
      const wordText = selectedWordInstance?.text;

      if (selectedWordInstance?.stem?._id === selectedFragment) {
        const stemText = selectedWordInstance?.stem?.text;
        selectionText = stemText;
        if (wordText.length > stemText.length) {
          selectionText = selectionText + "-";
        }
      } else {
        let endingText = "";

        if (selectedWordInstance?.ending1?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending1?.text;
        } else if (selectedWordInstance?.ending2?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending2?.text;
        } else if (selectedWordInstance?.ending3?._id === selectedFragment) {
          endingText = selectedWordInstance?.ending3?.text;
        }

        selectionText = endingText;
        if (wordText.length > endingText.length) {
          selectionText = "-" + selectionText;
        }
      }
    }

    return selectionText;
  };

  let content = (
    <div className={styles.container} ref={containerRef}>
      <div className={styles.colorPatch}> </div>
      <div className={styles.positionToolbar}>
        <div className={styles.selectionContainer}>
          <p className={styles.selectionText}>{selectionText()}</p>
        </div>
        <div className={styles.toolbarContainer}>
          <button
            onClick={() => onPrevFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Previous - Left Arrow Key"
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
          </button>
          <button
            onClick={() => onFragmentTTSClick()}
            type="button"
            className={
              selectedFragment
                ? `${styles.button}`
                : `${styles.button__disabled}`
            }
            title={
              selectedFragment ? "Play Word Text to Speech - Spacebar" : ""
            }
          >
            {selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faVolumeHigh} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faVolumeXmark} />
            )}
          </button>
          <button
            onClick={handleSeeTranslationClick}
            type="button"
            className={
              selectedFragment
                ? `${styles.button}`
                : `${styles.button__disabled}`
            }
            title={
              translationVisible[selectedWord]
                ? 'Hide Word Translation - "T" Key'
                : 'Show Word Translation - "T" Key'
            }
          >
            {!selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            ) : translationVisible[selectedWord] ? (
              <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faEye} />
            )}
          </button>
          <button
            onClick={toggleMarkAsKnown}
            type="button"
            className={
              selectedFragment
                ? `${styles.button}`
                : `${styles.button__disabled}`
            }
            title={
              vocabulary?.state !== "known"
                ? 'Mark Fragment as Known - "K" Key'
                : 'Revert Fragment to Seen - "K" Key'
            }
          >
            {!selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
            ) : vocabulary?.state !== "known" ? (
              <FontAwesomeIcon className={styles.icon} icon={faCheck} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
            )}
          </button>
          <button
            onClick={() => onNextFragmentClick(currentWordInstances)}
            type="button"
            className={styles.button}
            title="Next - Right Arrow Key"
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Toolbar;
