import React from "react";

import styles from "../AdminIndex.module.css";

const DropZone = ({ onFilesDropped }) => {
  const preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    preventDefaults(e);
    const files = [];
    const items = e.dataTransfer.items;

    for (let i = 0; i < items.length; i++) {
      const item = items[i].webkitGetAsEntry();
      if (item && item.isDirectory) {
        readDirectory(item, files).then(() => onFilesDropped(files));
      } else if (item && item.isFile) {
        files.push(items[i].getAsFile());
      }
    }
  };

  const readDirectory = (directoryEntry, files) => {
    const reader = directoryEntry.createReader();
    return new Promise((resolve) => {
      reader.readEntries((entries) => {
        const promises = entries.map((entry) => {
          if (entry.isFile) {
            return new Promise((res) => {
              entry.file((file) => {
                files.push(file);
                res();
              });
            });
          } else if (entry.isDirectory) {
            return readDirectory(entry, files);
          }
          return null;
        });
        Promise.all(promises).then(resolve);
      });
    });
  };

  return (
    <div
      className={styles.dragAndDrop}
      onDrop={handleDrop}
      onDragOver={preventDefaults}
      onDragEnter={preventDefaults}
      onDragLeave={preventDefaults}
    >
      Drag and drop a folder into this container to send an api request for each
      file.
    </div>
  );
};

export default DropZone;
