import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

export const wordInstancesAdapter = createEntityAdapter({});

const initialState = wordInstancesAdapter.getInitialState();

export const wordInstancesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllWordInstancesInBook: builder.query({
      query: ({ bookId, chapter }) => ({
        url: `/word-instances/${bookId}/${chapter}`,
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData, meta, arg) => {
        const loadedWordInstances = responseData.map((wordInstance) => {
          wordInstance.id = wordInstance._id;
          return wordInstance;
        });
        return wordInstancesAdapter.setAll(initialState, loadedWordInstances);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "WordInstance", id: "LIST" },
            ...result.ids.map((id) => ({ type: "WordInstance", id })),
          ];
        } else return [{ type: "WordInstance", id: "LIST" }];
      },
    }),
  }),
});

// RTK Query automatically creates the following hooks
export const { useGetAllWordInstancesInBookQuery } = wordInstancesApiSlice;

// query result object
// OLD
// export const selectAllWordInstancesInBookResult =
//   wordInstancesApiSlice.endpoints.getAllWordInstancesInBook.select();
// NEW
export const selectAllWordInstancesInBookResult = (bookId, chapter) =>
  wordInstancesApiSlice.endpoints.getAllWordInstancesInBook.select({
    bookId,
    chapter,
  });

// memoized selector
// OLD
// export const selectAllWordInstancesInBookData = createSelector(
//   selectAllWordInstancesInBookResult,
//   (wordInstancesResult) => wordInstancesResult.data
// );

// BEST PRACTICE
export const selectWordInstancesBySentenceId = createSelector(
  // 1st Input Selector: Fetch the 'entities' from all word instances in a particular book
  (state, bookId, chapter) =>
    selectAllWordInstancesInBookResult(bookId, chapter)(state)?.data?.entities,
  // 2nd Input Selector: sentenceId as the filter criterion
  (_state, _bookId, _chapter, sentenceId) => sentenceId,
  // Result function: Filter the entities based on sentenceId
  (entities, sentenceId) =>
    entities
      ? Object.values(entities).filter(
          (wordInstance) => wordInstance.sentence === sentenceId
        )
      : []
);

// GetSelectors creates these selectors for the word instances
// export const {
//   selectAll: selectAllWordInstances,
//   selectById: selectWordInstanceById,
//   selectIds: selectWordInstanceIds,
// } = wordInstancesAdapter.getSelectors(
//   (state) => selectAllWordInstancesInBookData(state) ?? initialState
// );
