import React, { useState, createContext } from "react";

const TourContext = createContext();

const TourProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [currentTask, setCurrentTask] = useState(0);

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);
  const nextTask = () => setCurrentTask((prev) => prev + 1);
  const prevTask = () => setCurrentTask((prev) => prev - 1);

  return (
    <TourContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        currentTask,
        setCurrentTask,
        nextStep,
        prevStep,
        nextTask,
        prevTask,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export { TourContext, TourProvider };
