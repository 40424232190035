import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const initialState = {
  showing: JSON.parse(localStorage.getItem("notificationShowing")) || false,
  type: localStorage.getItem("notificationType") || null,
  message: localStorage.getItem("notificationMessage") || null,
  additionalFields:
    JSON.parse(localStorage.getItem("notificationAdditionalFields")) || {},
  persistant: true,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      // show notification upon set
      state.showing = true;
      // set notification
      const { type, message, additionalFields, persistant } = action.payload;
      state.type = type;
      state.message = message;
      state.additionalFields = additionalFields || {};
      state.persistant = persistant !== undefined ? persistant : true;

      // save to local storage
      if (state.persistant) {
        localStorage.setItem(
          "notificationShowing",
          JSON.stringify(state.showing)
        );
        localStorage.setItem("notificationType", state.type);
        localStorage.setItem("notificationMessage", state.message);
        localStorage.setItem(
          "notificationAdditionalFields",
          JSON.stringify(state.additionalFields)
        );
      }
    },
    clearNotification: (state) => {
      // hide notification upon clear
      state.showing = false;
      // clear redux state
      state.type = null;
      state.message = null;
      state.additionalFields = {};

      // clear local storage
      localStorage.removeItem("notificationShowing");
      localStorage.removeItem("notificationType");
      localStorage.removeItem("notificationMessage");
      localStorage.removeItem("notificationAdditionalFields");
    },
    hydrateNotification: (state) => {
      // fetch the latest state from localStorage
      state.showing =
        JSON.parse(localStorage.getItem("notificationShowing")) || false;
      state.type = localStorage.getItem("notificationType") || null;
      state.message = localStorage.getItem("notificationMessage") || null;
      state.additionalFields =
        JSON.parse(localStorage.getItem("notificationAdditionalFields")) || {};
    },
  },
});

export const { setNotification, clearNotification, hydrateNotification } =
  notificationSlice.actions;
export default notificationSlice.reducer;

export const selectNotificationShowing = (state) => state.notification.showing;

export const selectNotification = createSelector(
  (state) => state.notification,
  (notification) => ({
    type: notification.type,
    message: notification.message,
    additionalFields: notification.additionalFields,
  })
);
