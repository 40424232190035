import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import styles from "./FormErrMsg.module.css";

const FormErrMsg = ({ elements, setErrMsg }) => {
  const [showing, setShowing] = useState(true);

  return (
    showing && (
      <section className={styles.section}>
        <FontAwesomeIcon
          onClick={() => setShowing(false)}
          icon={faCircleXmark}
          className={styles.xmark}
        />
        <div className={styles.flexCol}>
          {elements?.length > 0
            ? elements.map((elem, idx) => (
                <React.Fragment key={idx}>{elem}</React.Fragment>
              ))
            : ``}
        </div>
      </section>
    )
  );
};

export default FormErrMsg;
