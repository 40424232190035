import React, { useEffect, useCallback, useRef, useContext } from "react";
import { TourContext } from "../TourProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faVolumeHigh,
  faVolumeXmark,
  faEye,
  faEyeSlash,
  faCheck,
  faRotateLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Toolbar.module.css";
import tourStyles from "../Tour.module.css";

const Toolbar = ({
  currentWordInstances,
  selectedWord,
  selectedFragment,
  translationVisible,
  handleSeeTranslationClick,
  onNextFragmentClick,
  onPrevFragmentClick,
  toggleMarkAsKnown,
  getVocabularyByFragmentId,
}) => {
  const { currentTask, setCurrentTask, nextTask, prevTask } =
    useContext(TourContext);

  const vocabulary = getVocabularyByFragmentId(selectedFragment);
  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  // initialize only once
  if (!audioRef.current) {
    audioRef.current = new Audio();
  }

  // memoized
  // TODO: rename me
  const onFragmentTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    audioRef.current.src = `/spanish-words/esta.wav`;

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [selectedWordInstance]);

  // setting0: autoPlayWordTTS
  useEffect(() => {
    if (currentTask === 3) {
      onFragmentTTSClick();
    }
  }, [selectedWord, onFragmentTTSClick]);

  // previous key mapping (left arrow)
  // useEffect(() => {
  //   function handleKeyDown(event) {
  //     if (event.key === "ArrowLeft") {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       onPrevFragmentClick(currentWordInstances);
  //     }
  //   }

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [currentWordInstances, onPrevFragmentClick]);

  // selectionText
  const selectionText = () => {
    let selectionText = "--";

    if (selectedFragment) {
      const wordText = selectedWordInstance?.text;
      if (wordText) {
        // check stem
        if (selectedWordInstance?.stem?._id === selectedFragment) {
          const stemText = selectedWordInstance?.stem?.text;
          selectionText = stemText;
          // check dash
          if (wordText.length > stemText.length) {
            selectionText = selectionText + "-";
          }
        }

        // check ending
        else {
          let endingText = "";

          if (selectedWordInstance?.ending1?._id === selectedFragment) {
            endingText = selectedWordInstance?.ending1?.text;
          } else if (selectedWordInstance?.ending2?._id === selectedFragment) {
            endingText = selectedWordInstance?.ending2?.text;
          } else if (selectedWordInstance?.ending3?._id === selectedFragment) {
            endingText = selectedWordInstance?.ending3?.text;
          }

          selectionText = endingText;

          // check dash
          if (wordText.length > endingText.length) {
            selectionText = "-" + selectionText;
          }
        }
      }
    }

    return selectionText;
  };

  let content;

  content = (
    <div className={styles.container}>
      <div className={styles.colorPatch}> </div>
      <div className={styles.positionToolbar}>
        {/* <div className={styles.colorPatch}> </div> */}
        <div className={styles.selectionContainer}>
          <p className={styles.selectionText}>{selectionText()}</p>
        </div>
        <div className={styles.toolbarContainer}>
          {/* previous button */}
          <button
            onClick={() => onPrevFragmentClick(currentWordInstances)}
            type="button"
            className={`${styles.button}
            ${currentTask === 5 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 6 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 13 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 14 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 15 ? `${tourStyles.taskFocus__unclickable}` : ``}
            ${currentTask === 16 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 17 ? `${tourStyles.unclickable}` : ``}
            `}
            title="Previous - Left Arrow Key"
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
          </button>

          {/* TTS button */}
          <button
            onClick={() => onFragmentTTSClick()}
            type="button"
            className={`
              ${
                selectedFragment
                  ? `${styles.button}`
                  : `${styles.button__disabled}`
              }
                ${currentTask === 5 ? `${tourStyles.taskFocus}` : ``}
                ${currentTask === 6 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 13 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 14 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 15 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 16 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 17 ? `${tourStyles.unclickable}` : ``}
                `}
            title={
              selectedFragment ? "Play Word Text to Speech - Spacebar" : ""
            }
          >
            {selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faVolumeHigh} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faVolumeXmark} />
            )}
          </button>

          {/* toggle translation button */}
          <button
            onClick={handleSeeTranslationClick}
            type="button"
            className={`
              ${
                selectedFragment
                  ? `${styles.button}`
                  : `${styles.button__disabled}`
              }
              ${currentTask === 5 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 6 ? `${tourStyles.taskFocus}` : ``}
              ${currentTask === 13 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 14 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 15 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 16 ? `${tourStyles.unfocus__unclickable}` : ``}
              ${currentTask === 17 ? `${tourStyles.unclickable}` : ``}
              `}
            title={
              translationVisible[selectedWord]
                ? 'Hide Word Translation - "T" Key'
                : 'Show Word Translation - "T" Key'
            }
          >
            {!selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            ) : translationVisible[selectedWord] ? (
              <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faEye} />
            )}
          </button>

          {/* toggle mark as known button */}
          <button
            onClick={() => {
              toggleMarkAsKnown();
              if (currentTask === 13) {
                nextTask();
              }
            }}
            type="button"
            className={`${
              selectedFragment
                ? `${styles.button}`
                : `${styles.button__disabled}`
            }
            ${currentTask === 5 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 6 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 13 ? `${tourStyles.taskFocus}` : ``}
            ${currentTask === 14 ? `${tourStyles.taskFocus}` : ``}
            ${currentTask === 15 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 16 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 17 ? `${tourStyles.unclickable}` : ``}
            `}
            title={
              vocabulary?.state !== "known"
                ? 'Mark Fragment as Known - "K" Key'
                : 'Revert Fragment to Seen - "K" Key'
            }
          >
            {!selectedFragment ? (
              <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
            ) : vocabulary?.state !== "known" ? (
              <FontAwesomeIcon className={styles.icon} icon={faCheck} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faRotateLeft} />
            )}
          </button>

          {/* next button */}
          <button
            onClick={() => {
              onNextFragmentClick(currentWordInstances);
              if (currentTask === 16) {
                nextTask();
              }
            }}
            type="button"
            className={`${styles.button}
            ${currentTask === 5 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 6 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 13 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 14 ? `${tourStyles.unfocus__unclickable}` : ``}
            ${currentTask === 15 ? `${tourStyles.taskFocus__unclickable}` : ``}
            ${currentTask === 16 ? `${tourStyles.taskFocus}` : ``}
            ${currentTask === 17 ? `${tourStyles.unclickable}` : ``}
            `}
            title="Next - Right Arrow Key"
          >
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default Toolbar;
