import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const VocabularyHelp = () => {
  const [showingHelp, setShowingHelp] = useState(false);

  const handleClick = async () => {
    setShowingHelp(!showingHelp);
    // await handleUpdateUserHasLoggedIn();
  };

  return (
    <li className={`${styles.helpList__colItem} `}>
      <div className={styles.h3Row} onClick={handleClick}>
        {showingHelp ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Vocabulary Tracking</h3>
      </div>

      {showingHelp && (
        <div className={`${styles.vocabularyHelp} ${styles.bottomPadding}`}>
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <p>
                The target sentence is presented with our unique system of
                emphasis.
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Fragments</h4>
            <div className={styles.helpSection__contents}>
              <p>
                <em>Fragments</em>, not words, are tracked in your vocabulary. A
                fragment is a part of a word or a whole word. Therefore, a word
                consists of one or more fragments.
              </p>
              <p>
                A fragment is uniquely determined by its spelling and
                morphological significance.
              </p>
              <p>
                We refer to the first fragment in a word as the <em>stem</em>.
                The rest of the fragments in the word, if any, are called{" "}
                <em>endings</em>.
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>States</h4>
            <div className={styles.helpSection__contents}>
              <p className={styles.p}>
                Your vocabulary consists of <em>fragments</em> in 1 of 3 states:{" "}
                <em>new</em>, <em>seen</em>, and <em>known</em>.
              </p>
              <p>
                The type of fragment and the state of your vocabulary associated
                with it can be determined at a glance with our coloring system.
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>New Fragment Examples</h4>
            <div className={styles.helpSection__contents}>
              <p>New stems appear green. </p>
              <p className={`new stem ${styles.example}`}>mundo</p>
              <p>New endings appear as shades of yellow.</p>
              <p className={styles.example}>
                <span className="new stem">confirm</span>
                <span className="new ending1">ándo</span>
                <span className="new ending2">se</span>
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Seen Fragment Examples</h4>
            <div className={styles.helpSection__contents}>
              <p>Seen stems appear blue. </p>
              <p className={`seen stem ${styles.example}`}>mundo</p>
              <p>Seen endings appear as shades of magenta.</p>
              <p className={styles.example}>
                <span className="seen stem">confirm</span>
                <span className="seen ending1">ándo</span>
                <span className="seen ending2">se</span>
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Known Fragment Example</h4>
            <div className={styles.helpSection__contents}>
              <p>Known stems & endings appear white.</p>
              <p className={`known ${styles.example}`}>los</p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Mixed Examples</h4>
            <div className={styles.helpSection__contents}>
              <p>
                And, of course, words can appear with fragments in various
                states.
              </p>
              <p className={styles.example}>
                <span className="new stem">algun</span>
                <span className="seen ending1">a</span>
              </p>
              <p className={styles.example}>
                <span className="seen stem">desentrañ</span>
                <span className="seen ending1">ar</span>
                <span className="new ending2">les</span>
              </p>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Known Fragment Count</h4>
            <div className={styles.helpSection__contents}>
              <p>
                Your total number of known fragments is displayed in the top
                right next to the target language flag.
              </p>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default VocabularyHelp;
