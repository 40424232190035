import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { store } from "../../app/store";
import { profileApiSlice } from "../profile/profileApiSlice";
import { vocabularyApiSlice } from "../profile/vocabularyApiSlice";

const TEN_MINUTES_IN_MS = 10 * 60 * 1000;

const getLastUpdate = () => localStorage.getItem("lastBookProgressUpdate");
const setLastUpdate = (timestamp) =>
  localStorage.setItem("lastBookProgressUpdate", timestamp);

const isDataOld = (timestamp) =>
  !timestamp || Date.now() - timestamp > TEN_MINUTES_IN_MS;

const hasVocabularyMutationRun = () => {
  return localStorage.getItem("vocabularyMutationFulfilled") === "true";
};

const Prefetch = () => {
  useEffect(() => {
    /** this mutation runs when all of the following criteria are met:
     * component has mounted
     * data is at least 10 minutes old
     * a flag in localStorage indicates that a vocabulary mutation was fulfilled
     */
    const lastUpdate = getLastUpdate();
    const now = Date.now();

    if (!lastUpdate) {
      setLastUpdate(now);
    } else if (isDataOld(lastUpdate) && hasVocabularyMutationRun()) {
      store.dispatch(
        profileApiSlice.endpoints.updateAllUserBookProgress.initiate()
      );
      setLastUpdate(now);
      localStorage.removeItem("vocabularyMutationFulfilled");
    }
    // TESTING
    // store.dispatch(
    //   profileApiSlice.endpoints.updateAllUserBookProgress.initiate()
    // );

    store.dispatch(
      profileApiSlice.util.prefetch("getUserProfile", "userProfile", {
        force: true,
      })
    );

    store.dispatch(
      vocabularyApiSlice.util.prefetch("getUserVocabulary", "userVocabulary", {
        force: true,
      })
    );
  }, []);

  return <Outlet />;
};

export default Prefetch;
