import React from "react";
import { useUpdateUserHasSeenTutorialMutation } from "../../profile/profileApiSlice";

import styles from "./TourPopup.module.css";

const TourPopup = ({
  setTourActive,
  setCurrentTourStep,
  setShowingTourPopup,
}) => {
  const [updateUserHasSeenTutorial] = useUpdateUserHasSeenTutorialMutation();

  const handleBeginClick = async () => {
    try {
      await updateUserHasSeenTutorial({ value: true }).unwrap();
      setTourActive(true);
      setCurrentTourStep(1);
    } catch (err) {
      console.error("error updating user hasSeenTutorial:", err);
    }
  };

  const handleSkipClick = async () => {
    try {
      await updateUserHasSeenTutorial({ value: true }).unwrap();
      setShowingTourPopup(false);
    } catch (err) {
      console.error("error updating user hasSeenTutorial:", err);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.overlay}>
        <h2 className={styles.h2}>Single Sentence View Tutorial</h2>
        <button className={styles.begin} onClick={handleBeginClick}>
          Begin
        </button>
        <p className={styles.skip} onClick={handleSkipClick}>
          skip
        </p>
      </div>
    </div>
  );
};

export default TourPopup;
