import { useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "./AuthContext";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import ErrMsg from "../../components/shared/ErrMsg/ErrMsg";

const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  const { jwt, isLoading, roles } = useContext(AuthContext);

  let content;

  if (isLoading) {
    content = <LoadMsg msg={"loading..."} size={"2x"} />;
  } else if (!jwt) {
    content = (
      <ErrMsg
        msgList={["your login has expired"]}
        size={"2x"}
        additionalComponent={
          <p>
            Please <Link to="/login">login</Link> again
          </p>
        }
      />
    );
  } else if (roles?.some((role) => allowedRoles.includes(role))) {
    content = <Outlet />;
  } else {
    content = <Navigate to="/login" state={{ from: location }} replace />;
  }

  return content;
};
export default RequireAuth;
