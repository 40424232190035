import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const GrammarHelp = () => {
  const [showingNavigation, setShowingNavigation] = useState(false);

  return (
    <li className={`${styles.helpList__colItem} `}>
      <div
        className={styles.h3Row}
        onClick={() => setShowingNavigation(!showingNavigation)}
      >
        {showingNavigation ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Morphology</h3>
      </div>

      {showingNavigation && (
        <div className={`${styles.grammarHelp} ${styles.bottomPadding}`}>
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <ul className={styles.bulletedList}>
                <li>
                  morphology section is located above the toolbar to the right
                </li>
                <li>
                  the attributes of the currently selected word are listed here
                </li>
                <li>
                  the attributes of the currently selected fragment are
                  emphasized
                </li>
                <li>
                  tapping an attribute expands an accordian of additional
                  information for each
                </li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Attributes</h4>
            <div className={styles.helpSection__contents}>
              <p>
                The attributes of the currently selected word are listed, and
                you can tap each to find more information. For example, tapping
                "noun" would reveal its definition as well as the definition of
                part of speech.
              </p>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default GrammarHelp;
