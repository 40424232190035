// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCircleInfo,
//   faCircleXmark,
//   faChevronRight,
//   faChevronDown,
// } from "@fortawesome/free-solid-svg-icons";

// import styles from "./GrammarItem.module.css";

// const GrammarItem = ({
//   itemName,
//   itemDescription,
//   value,
//   valueDescription,
//   selected,
//   color,
// }) => {
//   const [showingValueDescription, setShowingDescription] = useState(false);
//   const [showingItemDescription, setShowingItemDescription] = useState(false);

//   useEffect(() => {
//     if (!showingValueDescription) {
//       setShowingItemDescription(false);
//     }
//   }, [showingValueDescription]);

//   return (
//     <div className={styles.container}>
//       <div
//         className={styles.itemRow}
//         onClick={() => setShowingDescription(!showingValueDescription)}
//       >
//         {/* <p className={selected ? styles.item__selected : styles.item}> */}
//         <p className={styles.item}>
//           <span className={color}>{itemName}</span>: {value}
//         </p>
//         <div className={styles.infoIcon}>
//           {showingValueDescription ? (
//             <FontAwesomeIcon icon={faChevronDown} />
//           ) : (
//             <FontAwesomeIcon icon={faChevronRight} />
//           )}
//         </div>
//       </div>
//       {showingValueDescription && (
//         <section className={styles.descriptionContainer}>
//           <div className={styles.itemInfoContainer}>
//             <p className={styles.itemName}>{itemName}</p>
//             {showingItemDescription ? (
//               <FontAwesomeIcon
//                 icon={faCircleXmark}
//                 onClick={() => setShowingItemDescription(false)}
//               />
//             ) : (
//               <FontAwesomeIcon
//                 icon={faCircleInfo}
//                 onClick={() => setShowingItemDescription(true)}
//               />
//             )}
//           </div>
//           {showingItemDescription && (
//             <p className={styles.itemDescription}>{itemDescription}</p>
//           )}
//           {showingValueDescription && (
//             <p className={styles.valueDescription}>
//               {valueDescription && (
//                 <>
//                   <span className={styles.valueSpan}>{value}</span> -{" "}
//                   {valueDescription}
//                 </>
//               )}
//             </p>
//           )}
//         </section>
//       )}
//     </div>
//   );
// };

// export default GrammarItem;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleInfo,
  faCircleXmark,
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./GrammarItem.module.css";

const GrammarItem = ({
  itemName,
  itemDescription,
  value,
  valueDescription,
  selected,
  color,
}) => {
  const [showingValueDescription, setShowingDescription] = useState(false);

  return (
    <div className={styles.container}>
      {showingValueDescription ? (
        <>
          <section className={styles.descriptionContainer}>
            <div className={styles.itemContainer}>
              <div className={styles.itemInfoContainer}>
                <p className={`${color} ${styles.itemName}`}>{itemName}: </p>
                <FontAwesomeIcon
                  className={styles.icon}
                  icon={faChevronDown}
                  onClick={() => setShowingDescription(false)}
                />
              </div>
              <p className={styles.itemDescription}>{itemDescription}</p>
            </div>
            {value && (
              <p className={styles.valueDescription}>
                <>
                  {valueDescription ? (
                    <>
                      <span className={styles.valueSpan}>{value}</span> -{" "}
                      {valueDescription}
                    </>
                  ) : (
                    <>
                      <span className={styles.valueSpan}>{value}</span>
                    </>
                  )}
                </>
              </p>
            )}
          </section>
        </>
      ) : (
        <div
          className={styles.itemRow}
          onClick={() => setShowingDescription(true)}
        >
          <p className={styles.item}>
            <span
              className={`${color}
            ${styles.itemName}
            `}
            >
              {itemName}:{" "}
            </span>
            {value}
          </p>
          <div className={styles.infoIcon}>
            {showingValueDescription ? (
              <FontAwesomeIcon icon={faChevronDown} />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GrammarItem;
