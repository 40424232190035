import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const TTSHelp = () => {
  const [showingTTS, setShowingTTS] = useState(false);

  return (
    <li className={`${styles.helpList__colItem} `}>
      <div className={styles.h3Row} onClick={() => setShowingTTS(!showingTTS)}>
        {showingTTS ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Text To Speech</h3>
      </div>

      {showingTTS && (
        <div className={`${styles.TTSHelp} ${styles.bottomPadding}`}>
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <ul className={styles.bulletedList}>
                <li>word and sentence text-to-speech (TTS) available</li>
                <li>see Settings to adjust default TTS playback behavior</li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Word TTS</h4>
            <div className={styles.helpSection__contents}>
              <p>Hear the TTS of a word by selecting it then:</p>
              <ul className={styles.bulletedList}>
                <li>tap the speaker icon in the toolbar OR</li>
                <li>press the spacebar on your keyboard</li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Sentence TTS</h4>
            <div className={styles.helpSection__contents}>
              <p>Hear the TTS of the sentence by:</p>
              <ul className={styles.bulletedList}>
                <li>tapping the speaker icon in the sentence section OR</li>
                <li>pressing the "s" key on your keyboard</li>
              </ul>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default TTSHelp;
