import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

import styles from "./SuccessMsg.module.css";

const SuccessMsg = ({ elements }) => {
  return (
    <section className={styles.section}>
      <FontAwesomeIcon icon={faCircleCheck} className={styles.checkmark} />
      <div className={styles.flexCol}>
        {elements?.length > 0
          ? elements.map((elem, idx) => (
              <React.Fragment key={idx}>{elem}</React.Fragment>
            ))
          : ``}
      </div>
    </section>
  );
};

export default SuccessMsg;
