import React from "react";
import useTitle from "../../../../../hooks/useTitle";

// import styles from "./ReportIssue.module.css";

const ReportIssue = () => {
  useTitle("Report Issue");

  const content = <h2>Report an Issue</h2>;

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default ReportIssue;
