import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { AuthContext } from "../../../../features/auth/AuthContext";
import { useGetUserProfileQuery } from "../../../../features/profile/profileApiSlice";
import LogoMono from "../../LogoMono/Logo";
import BetaBadge from "../../BetaBadge/BetaBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faXmark,
  faUserCheck,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./MainNav.module.css";

const MainNav = () => {
  const { auth, username } = useContext(AuthContext);
  const { data: userProfile } = useGetUserProfileQuery("userProfile");
  const displayUsername = username || userProfile?.username;

  const location = useLocation();

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 410);
  const [drawerShowing, setDrawerShowing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 410);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setDrawerShowing(false);
  }, [location.pathname]);

  useEffect(() => {
    drawerShowing && !auth && setDrawerShowing(false);
  }, [drawerShowing, auth]);

  useEffect(() => {
    if (!isMobileView) {
      setDrawerShowing(false);
    }
  }, [isMobileView]);

  const toggleDrawerShowing = () => {
    setDrawerShowing(!drawerShowing);
  };

  return (
    <header className={styles.container}>
      <div className={styles.topRow}>
        {/* logo */}
        <div className={styles.logoContainer}>
          <Link to="/">
            <LogoMono />
          </Link>
          <BetaBadge />
        </div>

        {/* menu */}
        {auth ? (
          isMobileView ? (
            <div className={styles.hamburgerContainer}>
              <button
                className={styles.hamburger}
                onClick={toggleDrawerShowing}
              >
                {drawerShowing ? (
                  <FontAwesomeIcon icon={faXmark} />
                ) : (
                  <FontAwesomeIcon icon={faBars} />
                )}
              </button>
            </div>
          ) : (
            <div className={styles.desktopMenuContainer}>
              <nav>
                <ul className={styles.desktopMenuList}>
                  <li>
                    <NavLink
                      to="library"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      library
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="profile"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="settings"
                      className={({ isActive }) =>
                        isActive
                          ? `${styles.desktopMenuItem} ${styles.active}`
                          : `${styles.desktopMenuItem}`
                      }
                    >
                      settings
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          )
        ) : (
          <></>
        )}

        {/* auth */}
        <div className={styles.authContainer}>
          {auth ? (
            <Link to="profile" className={styles.userLink}>
              <FontAwesomeIcon icon={faUserCheck} className={styles.userIcon} />
              <span className={styles.username}>
                {" "}
                {displayUsername?.slice(0, 5)}
              </span>
            </Link>
          ) : (
            <>
              <li>
                <Link to="login" className={styles.login}>
                  login
                </Link>
              </li>
              <li>
                <Link to="sign-up" className={styles.signUp}>
                  sign up
                </Link>
              </li>
            </>
          )}
        </div>
      </div>

      {/* mobile menu drawer */}
      {drawerShowing ? (
        <nav className={styles.drawer}>
          <ul className={styles.mobileMenu}>
            <li className={styles.mobileMenu_item}>
              <Link to="library" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>library</div>
              </Link>
            </li>
            <li className={styles.mobileMenu_item}>
              <Link to="profile" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>profile</div>
              </Link>
            </li>
            <li className={styles.mobileMenu_item}>
              <Link to="settings" className={styles.mobileMenu_link}>
                <div className={styles.mobileMenu_link_row}>settings</div>
              </Link>
            </li>
          </ul>
        </nav>
      ) : (
        <></>
      )}
    </header>
  );
};

export default MainNav;
