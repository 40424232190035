import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const booksAdapter = createEntityAdapter({});

const initialState = booksAdapter.getInitialState();

export const booksApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllBooks: builder.query({
      query: () => ({
        url: "/books",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedBooks = responseData.map((book) => {
          book.id = book._id;
          return book;
        });
        return booksAdapter.setAll(initialState, loadedBooks);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Book", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Book", id })),
          ];
        } else return [{ type: "Book", id: "LIST" }];
      },
    }),
    getBookById: builder.query({
      query: (bookId) => `/books/${bookId}`,
      transformResponse: (responseData) => {
        responseData.id = responseData._id;
        return responseData;
      },
      providesTags: (result, error, bookId) => {
        if (result) {
          return [{ type: "Book", id: bookId }];
        }
      },
    }),
  }),
});

// RTK Query automatically creates the following hooks
export const { useGetAllBooksQuery, useGetBookByIdQuery } = booksApiSlice;

// query result object
export const selectAllBooksResult =
  booksApiSlice.endpoints.getAllBooks.select("library");

// memoized selector
const selectAllBooksData = createSelector(
  selectAllBooksResult,
  (booksResult) => booksResult.data // normalized state object w/ ids & entities
);

// rename & export selectors created by createSelector
export const {
  selectAll: selectAllBooks,
  selectById: selectBookById,
  selectIds: selectBookIds,
  // pass in a selector that returns the book slice of state
} = booksAdapter.getSelectors(
  (state) => selectAllBooksData(state) ?? initialState
);
