import React, { useEffect, useRef, useState, useContext } from "react";
import { TourContext } from "../TourProvider";

import styles from "./Target.module.css";
import tourStyles from "../Tour.module.css";

const WordInstance = ({
  wordInstanceId,
  translation,
  leadingPunctuation,
  trailingPunctuation,
  translationVisible,
  isSelectedWord,
  slices,
  handleFragmentSelection,
  language,
  olLeftOffset,
  olRightOffset,
  indent,
}) => {
  const { currentTask, nextTask } = useContext(TourContext);

  const [alignmentClass, setAlignmentClass] = useState("");
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const threshold = 10;
      const touchesLeft = rect.left <= olLeftOffset + threshold;
      const touchesRight = rect.right >= olRightOffset - threshold;

      if (touchesLeft) {
        setAlignmentClass(styles.alignLeft);
      } else if (touchesRight) {
        setAlignmentClass(styles.alignRight);
      } else {
        setAlignmentClass("");
      }
    }
  }, [olLeftOffset, olRightOffset]);

  return (
    <li
      key={wordInstanceId}
      className={`${indent ? `${styles.paragraphIndentation}` : ""}
        ${
          currentTask === 999 && wordInstanceId === "1"
            ? `${tourStyles.taskFocus__unclickable}`
            : ``
        }
      `}
    >
      <table
        ref={tableRef}
        className={`${
          currentTask === 6 && wordInstanceId === "1"
            ? `${tourStyles.iPhoneSucks}`
            : ``
        }`}
      >
        <tbody>
          {/* translation row */}
          <tr
            className={`${styles.translation} ${
              translationVisible[wordInstanceId] ? "" : "invisible"
            }
            ${
              currentTask === 6 && wordInstanceId === "1"
                ? `${tourStyles.taskFocus__unclickable}`
                : ``
            }
            `}
          >
            {leadingPunctuation && <td className={styles.punctuation}></td>}
            <td
              className={`${
                currentTask === 6 && wordInstanceId === "1"
                  ? `${tourStyles.iPhoneSucks}`
                  : ``
              }`}
            >
              {translation}
            </td>
            {trailingPunctuation && <td className={styles.punctuation}></td>}
          </tr>

          {/* word row */}
          <tr
            id={wordInstanceId}
            className={`${styles.wordInstance}
          
          `}
          >
            {leadingPunctuation && (
              <td className={styles.punctuation}>{leadingPunctuation}</td>
            )}
            <td
              className={`${styles.wordInstanceText} ${alignmentClass}
            ${
              currentTask === 5 && wordInstanceId === "1"
                ? `${tourStyles.taskFocusOutlineOffset__unclickable}`
                : ``
            }
            ${
              currentTask === 6 && wordInstanceId === "1"
                ? `${tourStyles.unclickable}`
                : ``
            }`}
            >
              {slices.map((slice, index) => (
                <span
                  onClick={() => {
                    handleFragmentSelection(
                      wordInstanceId,
                      slice.fragmentId,
                      slice.vocabulary
                    );
                    currentTask === 2 && nextTask();
                  }}
                  id={slice.fragmentId}
                  key={slice.fragmentId}
                  className={`${styles.fragment}
                  ${isSelectedWord ? `${styles.selectedWord}` : ``}
                    ${
                      slice.isSelectedFragment
                        ? `${styles.selectedFragment}`
                        : ``
                    }
                    ${slice.type}
                    ${slice.vocabulary?.state || "new"}
                   
                    ${
                      currentTask === 1
                        ? `${tourStyles.taskFocus__unclickable}`
                        : ``
                    }
                    ${
                      slice.fragmentId === "est"
                        ? currentTask === 2
                          ? `${tourStyles.taskFocus}`
                          : ``
                        : ``
                    }
                    ${
                      slice.fragmentId === "est"
                        ? currentTask === 4
                          ? `${tourStyles.taskFocus__unclickable}`
                          : ``
                        : ``
                    }
                    ${
                      slice.fragmentId === "est"
                        ? currentTask === 8
                          ? `${tourStyles.taskFocus__unclickable}`
                          : ``
                        : ``
                    }
                    ${
                      slice.fragmentId === "est"
                        ? currentTask === 13
                          ? `${tourStyles.taskFocus__unclickable}`
                          : ``
                        : ``
                    }
                    ${
                      slice.fragmentId === "est"
                        ? currentTask === 14
                          ? `${tourStyles.taskFocusNoBorder__unclickable}`
                          : ``
                        : ``
                    }
                    `}
                >
                  {slice.text}
                </span>
              ))}
            </td>
            {trailingPunctuation && (
              <td className={styles.punctuation}>{trailingPunctuation}</td>
            )}
          </tr>

          {/* language row */}
          <tr className={styles.languageRow}>
            <td>
              {language !== "Spanish" && (
                <span className={styles.wordInstanceLanguage}>{language}</span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </li>
  );
};

export default WordInstance;
