import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";

import styles from "./ErrMsg.module.css";

const ErrMsg = ({ msgList, size, additionalComponent, dynaHeight }) => {
  return (
    <div
      className={`${styles.container} ${
        dynaHeight === true ? styles.dynaHeight : ``
      }`}
    >
      <FontAwesomeIcon
        icon={faCircleExclamation}
        size={size}
        className={styles.icon}
      />
      {msgList?.length > 0 ? (
        msgList.map((msg) => <p key={msg}>{msg?.toUpperCase()}</p>)
      ) : (
        <p>SOMETHING WENT WRONG</p>
      )}
      {additionalComponent && additionalComponent}
    </div>
  );
};

export default ErrMsg;
