import React, { useContext } from "react";
import { AuthContext } from "../auth/AuthContext";
import { useNavigate, Link } from "react-router-dom";
import { useGetUserProfileQuery } from "../profile/profileApiSlice";
import UndefinedUsername from "./UndefinedUsername/UndefinedUsername";
// import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
// import ErrMsg from "../../components/shared/ErrMsg/ErrMsg";
// import StripeDisplay from "./StripeDisplay";
import ConstructionMsg from "../../components/shared/ConstructionMsg/ConstructionMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import useTitle from "../../hooks/useTitle";

import styles from "./Profile.module.css";

const Profile = () => {
  useTitle("Profile");

  const { logout, isAdmin } = useContext(AuthContext);
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const navigate = useNavigate();

  const handleLogoutClick = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (err) {}
  };

  const logoutButton = (
    <button
      className={styles.logout}
      title="logout"
      onClick={handleLogoutClick}
    >
      Logout
      <FontAwesomeIcon icon={faRightFromBracket} />
    </button>
  );

  let content;

  if (userProfile) {
    const username = userProfile.username || "user";

    const date = new Date(userProfile.createdAt);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    content = (
      <section className={styles.container}>
        <div className={styles.topBanner}>
          <div className={styles.usernameContainer}>
            <h2 className={styles.header}>
              <span className="nowrap">{username.slice(0, 20)}</span>
            </h2>
            <p className={styles.memberhipDate}>
              Member since: {formattedDate}
            </p>
          </div>

          {logoutButton}
        </div>

        {isAdmin && (
          <Link to="/admin" className={styles.adminLink}>
            /admin
          </Link>
        )}

        {!userProfile.username && <UndefinedUsername />}

        {/* TODO: move to production */}
        {/* {process.env.NODE_ENV !== "production" && (
          <StripeDisplay arg={"Message"} />
        )} */}

        <div className="grid-center">
          <ConstructionMsg />
        </div>
      </section>
    );
  }

  return <main className="page-wrapper">{content}</main>;
};

export default Profile;
