import React from "react";
import { Link } from "react-router-dom";

import styles from "./NotFound.module.css";

const NotFound = () => {
  const content = (
    <div className={styles.container}>
      <h2>Oops! The page you have requested does not exist.</h2>
      <p>
        Return <Link to="/">home</Link>
      </p>
    </div>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default NotFound;
