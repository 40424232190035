import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { v4 as uuidv4 } from "uuid";

const vocabulariesAdapter = createEntityAdapter({
  selectId: (vocabulary) => vocabulary._id,
});

const initialState = vocabulariesAdapter.getInitialState();

export const vocabularyApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserVocabulary: builder.query({
      query: () => ({
        url: "/vocabularies",
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
      transformResponse: (responseData) => {
        const loadedVocabularies = responseData.map((vocab) => {
          vocab.id = vocab._id;
          return vocab;
        });
        return vocabulariesAdapter.setAll(initialState, loadedVocabularies);
      },
      providesTags: (result, error, arg) => {
        if (result?.ids) {
          return [
            { type: "Vocabulary", id: "LIST" },
            ...result.ids.map((id) => ({ type: "Vocabulary", id })),
          ];
        } else return [{ type: "Vocabulary", id: "LIST" }];
      },
    }),
    upsertUserVocabulary: builder.mutation({
      query: ({ vocabularyId, fragmentId, newState, languageId }) => ({
        url: "/vocabularies",
        method: "PUT",
        body: { vocabularyId, fragmentId, newState, languageId },
      }),
      async onQueryStarted(
        { vocabularyId, fragmentId, newState, languageId },
        { dispatch, queryFulfilled }
      ) {
        // console.log(`In onQueryStarted - vocabularyId: ${vocabularyId}`);
        if (!vocabularyId) {
          const tempVocabId = uuidv4();
          // console.log(`tempVocabId: ${tempVocabId}`);
          const patchResult = dispatch(
            vocabularyApiSlice.util.updateQueryData(
              "getUserVocabulary",
              "userVocabulary",
              (draft) => {
                draft.entities[tempVocabId] = {
                  id: tempVocabId,
                  _id: tempVocabId,
                  state: newState,
                  fragment: fragmentId,
                  language: languageId,
                  // Add other properties required by your vocabulary entity
                };
                draft.ids.push(tempVocabId);
              }
            )
          );

          try {
            const { data } = await queryFulfilled;
            const newVocabId = data.id; // Assume the response includes the new ID

            dispatch(
              vocabularyApiSlice.util.updateQueryData(
                "getUserVocabulary",
                "userVocabulary",
                (draft) => {
                  const tempVocab = draft.entities[tempVocabId];
                  if (tempVocab) {
                    // Update the entity with the new ID
                    delete draft.entities[tempVocabId];
                    draft.entities[newVocabId] = {
                      ...tempVocab,
                      id: newVocabId,
                      _id: newVocabId,
                    };
                    const index = draft.ids.indexOf(tempVocabId);
                    if (index !== -1) {
                      draft.ids[index] = newVocabId;
                    }
                  }
                }
              )
            );

            // Invalidate the tag to trigger a refetch if necessary
            dispatch(
              vocabularyApiSlice.util.invalidateTags([
                { type: "Vocabulary", id: "userVocabulary" },
              ])
            );
          } catch {
            patchResult.undo();
          }
        } else {
          const patchResult = dispatch(
            vocabularyApiSlice.util.updateQueryData(
              "getUserVocabulary",
              "userVocabulary",
              (draft) => {
                const existingVocabulary = draft.entities[vocabularyId];
                if (existingVocabulary) {
                  existingVocabulary.state = newState;
                }
              }
            )
          );

          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        }
      },
      // Add invalidatesTags to ensure the cache is invalidated properly
      // invalidatesTags: (result, error, { vocabId }) => [
      //   { type: "Vocabulary", id: vocabId || "userVocabulary" },
      // ],
    }),
  }),
});

// RTK Query automatically creates the following hooks
export const { useGetUserVocabularyQuery, useUpsertUserVocabularyMutation } =
  vocabularyApiSlice;

// query result object
export const selectUserVocabularyResult =
  vocabularyApiSlice.endpoints.getUserVocabulary.select();

// memoized selector
const selectUserVocabularyData = createSelector(
  selectUserVocabularyResult,
  (vocabulariesResult) => vocabulariesResult.data // normalized state object w/ ids & entities
);

// rename & export selectors created by createSelector
export const {
  selectAll: selectAllVocabularies,
  selectById: selectVocabularyById,
  selectIds: selectVocabularyIds,
  // pass in a selector that returns the book slice of state
} = vocabulariesAdapter.getSelectors(
  (state) => selectUserVocabularyData(state) ?? initialState
);
