import { apiSlice } from "../../app/api/apiSlice";
import { setCredentials } from "./authSlice";

import { baseUrl } from "../../config/baseUrl";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signUp: builder.mutation({
      query: (initialUserData) => ({
        url: "/auth/sign-up",
        method: "POST",
        body: {
          ...initialUserData,
        },
        validateStatus: (response, result) => {
          return response.status === 201 && !result.isError;
        },
      }),
    }),
    login: builder.mutation({
      query: (credentials) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...credentials },
      }),
      validateStatus: (response, result) => {
        return response.status === 200 && !result.isError;
      },
    }),
    logout: builder.mutation({
      query: () => ({
        url: "/auth/logout",
        method: "POST",
      }),
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "/auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const { accessToken } = data;
          dispatch(setCredentials({ accessToken }));
        } catch (err) {
          console.log(err);
        }
      },
    }),
    googleAuth: builder.mutation({
      queryFn: async () => {
        const loginUrl = `${baseUrl}/auth/login/google`;
        window.location.href = loginUrl; // redirect to the Google OAuth flow on the backend
        return { data: null };
      },
    }),
    resendVerificationEmail: builder.mutation({
      query: ({ email }) => ({
        url: "/email-verification/resend",
        method: "POST",
        body: { email },
        validateStatus: (response, result) => {
          return response.status === 200 && !result.isError;
        },
      }),
    }),
    sendForgotPasswordEmail: builder.mutation({
      query: ({ email }) => ({
        url: "/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),
    submitForgotPasswordCode: builder.mutation({
      query: ({ code }) => ({
        url: "/forgot-password/reset",
        method: "POST",
        body: { code },
      }),
    }),
  }),
});

export const {
  useSignUpMutation,
  useLoginMutation,
  useLogoutMutation,
  useRefreshMutation,
  useGoogleAuthMutation,
  useResendVerificationEmailMutation,
  useSendForgotPasswordEmailMutation,
  useSubmitForgotPasswordCodeMutation,
} = authApiSlice;
