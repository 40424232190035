import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectNotification } from "./notificationSlice";
import { clearNotification } from "./notificationSlice";
import { AuthContext } from "../../../../features/auth/AuthContext";
// import { triggerAnimation } from "../../../../utils/triggerAnimation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Notification.module.css";

const Notification = () => {
  const dispatch = useDispatch();

  const { message, type, additionalFields } = useSelector(selectNotification);

  const handleClearNotification = () => {
    // todo: animate disappearance
    // triggerAnimation();
    dispatch(clearNotification());
  };

  const { isLoading, resendVerificationEmail, sendForgotPasswordEmail } =
    useContext(AuthContext);

  let content;

  if (isLoading) return content;
  /** || SIGN UP NOTIFICAITONS */
  // created new user
  else if (type === "success" && message === "created new user") {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                Created new user, {username}. Check your inbox at {email} to
                verify your account.
              </p>
              <p className={styles.p}>
                Can't find the email in your inbox?{" "}
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  Resend link
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // duplicate email
  else if (type === "error" && message === "duplicate email") {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <p className={styles.p}>
              An account with this email already exists. Please enter a
              different email.
            </p>
          </div>
        </section>
      </>
    );
  }

  // duplicate username
  else if (type === "error" && message === "duplicate username") {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <p className={styles.p}>
              Username taken. Please enter a different username.
            </p>
          </div>
        </section>
      </>
    );
  }

  // unable to create new user
  else if (type === "error" && message === "unable to create new user") {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <p className={styles.p}>Invalid user data received.</p>
          </div>
        </section>
      </>
    );
  }
  /** || END SIGN UP NOTIFICATIONS */

  /** || EMAIL VERIFICATION NOTIFICATIONS */
  // verified email
  else if (type === "success" && message === "verified email") {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            <p className={styles.p}>
              Verified {email}. Please{" "}
              <Link to="/login" className={styles.link_success}>
                login
              </Link>
              , {username}.
            </p>
          </div>
        </section>
      </>
    );
  }

  // email verification link expired
  else if (type === "error" && message === "email verification link expired") {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleXmark} className={styles.xmark} />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                Your verification email expired, {username}.
              </p>
              <p className={styles.p}>
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  Get new link
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // resent verification email
  else if (type === "success" && message === "resent verification email") {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                Resent verification email, {username}. Check your inbox at{" "}
                {email} to verify your account.
              </p>
              <p className={styles.p}>
                Can't find the email in your inbox?{" "}
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  Resend link
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // unable to send verification email
  else if (
    type === "error" &&
    message === "unable to resend verification email"
  ) {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleXmark} className={styles.xmark} />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                An error occurred while trying to resend verification email,{" "}
                {username}.
              </p>
              <p className={styles.p}>
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  Try resending
                </span>{" "}
                to {email} again.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
  /** || END EMAIL VERIFICATION NOTIFICATIONS */

  /** || LOGIN NOTIFICATIONS */
  // unable to login with unverified account
  else if (
    type === "error" &&
    message === "unable to login with unverified account"
  ) {
    const { username, email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                Verify {email} to login to your account, {username}.
              </p>
              <p className={styles.p}>
                Can't find the email in your inbox?{" "}
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  Resend link
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // invalid username or password
  else if (type === "error" && message === "invalid username or password") {
    content = (
      <section className={styles.section}>
        <div className={styles.flexRow}>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className={`${styles.xmark} ${styles.dismissable}`}
            onClick={handleClearNotification}
          />
          <div className={styles.flexColumn}>
            <p className={styles.p}>
              The username or password you provided don't match our records.
            </p>
          </div>
        </div>
      </section>
    );
  }
  /** || END LOGIN NOTIFICATIONS */

  /** || FORGOT PASSWORD NOTIFICAITONS */
  // unable to send forgot password code to unverified email
  else if (
    type === "error" &&
    message === "unable to send forgot password code to unverified email"
  ) {
    const { email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                You must verify {email} before you can get a forgot password
                code.
              </p>
              <p>
                Check your inbox or{" "}
                <span
                  onClick={() => resendVerificationEmail(email)}
                  className={styles.resend}
                >
                  get new verification link
                </span>
                .
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // unable to send forgot password code to oauthed user
  else if (
    type === "error" &&
    message === "unable to send forgot password code to oauthed user"
  ) {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                This account signed up with Google. Use your Google profile to
                gain access to your account.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // sent forgot password code
  else if (type === "success" && message === "sent forgot password code") {
    const { email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                Sent reset password code to {email}. The code is valid for 15
                minutes.
              </p>
              <p className={styles.p}>
                Can't find the email in your inbox?{" "}
                <span
                  onClick={() => sendForgotPasswordEmail(email)}
                  className={`nowrap ${styles.resend}`}
                >
                  Resend code
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // unable to send forgot password code
  else if (
    type === "error" &&
    message === "unable to send forgot password code"
  ) {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <p className={styles.p}>
              The email address you provided doesn't match our records.
            </p>
          </div>
        </section>
      </>
    );
  }

  // forgot password code expired
  else if (type === "error" && message === "forgot password code expired") {
    const { email } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>Your code expired.</p>
              <p className={styles.p}>
                <span
                  onClick={() => sendForgotPasswordEmail(email)}
                  className={`nowrap ${styles.resend}`}
                >
                  Get new code
                </span>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }

  // invalid forgot password code
  else if (type === "error" && message === "invalid forgot password code") {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                The code you provided doesn't match our records.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
  /** || END FORGOT PASSWORD NOTIFICATIONS */

  /** || RESET PASSWORD NOTIFICATIONS */
  // updated password
  else if (type === "success" && message === "updated password") {
    const { username } = additionalFields;
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            <p className={styles.p}>
              You reset your password, {username}. Login with your new
              credentials.
            </p>
          </div>
        </section>
      </>
    );
  }

  // unable to reset password
  else if (type === "error" && message === "unable to reset password") {
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            <FontAwesomeIcon
              icon={faCircleXmark}
              className={`${styles.xmark} ${styles.dismissable}`}
              onClick={handleClearNotification}
            />
            <div className={styles.flexColumn}>
              <p className={styles.p}>
                An error occured while resetting your password.
              </p>
              <p className={styles.p}>
                Return to{" "}
                <Link to="/forgot-password" className={styles.link}>
                  forgot password
                </Link>
              </p>
            </div>
          </div>
        </section>
      </>
    );
  } else if (type && message) {
    /** || END RESET PASSWORD NOTIFICATIONS */

    /** || GENERIC NOTIFICATION (ambiguous to type & message) */
    content = (
      <>
        <section className={styles.section}>
          <div className={styles.flexRow}>
            {type === "success" ? (
              <FontAwesomeIcon icon={faCircleCheck} className={styles.check} />
            ) : (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className={`${styles.xmark} ${styles.dismissable}`}
                onClick={handleClearNotification}
              />
            )}
            <p className={styles.p}>{message}</p>
          </div>
        </section>
      </>
    );
  }

  return (
    <div
      id="notification"
      className={`${styles.container}
                    ${type === "success" ? `${styles.success}` : ``}
                    ${type === "error" ? `${styles.error}` : ``}`}
    >
      {content}
    </div>
  );
};

export default Notification;
