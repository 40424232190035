import React, { useState, useEffect, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmail from "validator/lib/isEmail";
import { AuthContext } from "../AuthContext";
import GoogleAuth from "../GoogleAuth";
import { selectNotification } from "../../../components/shared/AppLayout/notification/notificationSlice";
import useTitle from "../../../hooks/useTitle";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";
import SuccessMsg from "../../../components/shared/SuccessMsg/SuccessMsg";

import styles from "./ForgotPassword.module.css";

const ForgotPassword = () => {
  useTitle("Forgot Password");

  const {
    resendVerificationEmail,
    sendForgotPasswordEmail,
    submitForgotPasswordCode,
    isLoading,
  } = useContext(AuthContext);

  const { message, type } = useSelector(selectNotification);

  const navigate = useNavigate();

  const [curMsg, setCurMsg] = useState("help");
  const [curForm, setCurForm] = useState("email");

  // form input values
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    setValidEmail(isEmail(email));
    setCurMsg("help");
  }, [email]);

  useEffect(() => {
    if (type === "error") {
      setValidEmail(false);
    }
  }, [type]);

  // sync current message and form with notification
  useEffect(() => {
    // unable to send password reset code to unverified email
    if (
      type === "error" &&
      message === "unable to send forgot password code to unverified email"
    ) {
      setCurForm("none");
      setCurMsg("unverifiedError");
    } else if (type === "success" && message === "resent verification email") {
      setCurMsg("sentVerificationEmail");
      setCurForm("email");
    }
    // unable to send forgot password code to oauthed user
    else if (
      type === "error" &&
      message === "unable to send forgot password code to oauthed user"
    ) {
      setCurForm(null);
      setCurMsg("oauth");
    }
    // unable to send forgot password code
    else if (
      type === "error" &&
      message === "unable to send forgot password code"
    ) {
      setCurForm("email");
      setCurMsg("error");
    }
    // sent forgot password code
    else if (type === "success" && message === "sent forgot password code") {
      setCurForm("code");
      setCurMsg("success");
    }
    // forgot password code expired
    else if (type === "error" && message === "forgot password code expired") {
      setCurForm("email");
      setCurMsg("expired");
    }
    // invalid forgot password code
    else if (type === "error" && message === "invalid forgot password code") {
      setCurForm("code");
      setCurMsg("codeError");
    }
  }, [message, type, email]);

  const helpMsg = (
    <p className={styles.msg}>
      Enter your email address, and we'll send you a code to reset your
      password.
    </p>
  );

  const unverifiedMsg = (
    <FormErrMsg
      elements={[
        <p ref={null} aria-live="assertive" role="alert">
          You must verify your email before you can get a forgot password code.
        </p>,
        <p className={styles.checkInbox}>
          Check your inbox or{" "}
          <span
            onClick={() => resendVerificationEmail(email)}
            className={styles.resend}
          >
            get new verification link
          </span>
          .
        </p>,
      ]}
    />
  );

  const sentVerificationEmailMsg = (
    <SuccessMsg
      elements={[
        <p>
          Resent verification email. Check your inbox to verify your account.
        </p>,
      ]}
    />
  );

  const successMsg = (
    <SuccessMsg
      elements={[
        <p>Enter the code sent to your inbox to reset your password.</p>,
      ]}
    />
  );

  const errMsg = (
    <FormErrMsg
      elements={[
        <p ref={null} aria-live="assertive" role="alert">
          The email you provided doesn't match our records.
        </p>,
      ]}
    />
  );

  const codeErrMsg = (
    <FormErrMsg
      elements={[
        <p ref={null} aria-live="assertive" role="alert">
          The code you provided doesn't match our records.
        </p>,
      ]}
    />
  );

  const expiredMsg = (
    <FormErrMsg
      elements={[
        <p ref={null} aria-live="assertive" role="alert">
          Your code expired. Request another email to get a new code.
        </p>,
      ]}
    />
  );

  const canSubmit_emailForm = validEmail;
  const handleSubmit_emailForm = async (e) => {
    e.preventDefault();
    if (canSubmit_emailForm) {
      try {
        await sendForgotPasswordEmail(email);
        setCode("");
      } catch (err) {}
    }
  };

  const emailForm = (
    <form className="form" onSubmit={handleSubmit_emailForm}>
      {/* email */}
      <div className="form__col">
        <label className="form__label" htmlFor="email">
          Email:
        </label>
        <p className="form__requirements">verified email required</p>
        <div className="form__row">
          <input
            className={`form__input 
              ${
                email && validEmail
                  ? "form__input--valid"
                  : email && !validEmail
                  ? "form__input--invalid"
                  : ""
              }
              `}
            type="text"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            required
            autoFocus
            tabIndex="1"
          />
        </div>
      </div>

      {/* submit button by default since only 1 button in this form */}
      <button
        className={
          canSubmit_emailForm
            ? "form__submitButton"
            : "form__submitButton--disabled"
        }
        disabled={!canSubmit_emailForm}
        tabIndex="2"
      >
        Get Code
      </button>
    </form>
  );

  const handleSubmit_codeForm = async (e) => {
    e.preventDefault();
    try {
      await submitForgotPasswordCode(code);
      navigate("/reset-password");
    } catch (err) {}
  };

  const codeForm = (
    <form className="form" onSubmit={handleSubmit_codeForm}>
      {/* code */}
      <div className="form__col">
        <div className="form__row">
          <label className="form__label" htmlFor="code">
            Code:
          </label>
        </div>
        <div className="form__row">
          <input
            className="form__input"
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoComplete="off"
            required
            autoFocus
            tabIndex="1"
          />
        </div>
      </div>

      <button type="submit" className="form__submitButton" tabIndex="2">
        Submit Code
      </button>
    </form>
  );

  let content;

  if (isLoading) {
    content = <LoadMsg msg="loading" size="2x" />;
  } else {
    content =
      curMsg === "oauth" && !curForm ? (
        <>
          <div className={styles.oauth}>
            <GoogleAuth label="Continue with Google" />
          </div>
          <p className={styles.return}>
            <Link to="/login" className={styles.link}>
              Return to login
            </Link>
          </p>
        </>
      ) : (
        <>
          <h2 className={styles.h2}>Forgot Password</h2>

          <div className={styles.msgContainer}>
            {curMsg === "unverifiedError"
              ? unverifiedMsg
              : curMsg === "sentVerificationEmail"
              ? sentVerificationEmailMsg
              : curMsg === "error"
              ? errMsg
              : curMsg === "codeError"
              ? codeErrMsg
              : curMsg === "expired"
              ? expiredMsg
              : curMsg === "success"
              ? successMsg
              : helpMsg}
          </div>

          {curForm === "email" ? emailForm : curForm === "code" ? codeForm : ""}

          <p className={styles.return}>
            <Link to="/login" className={styles.link}>
              Return to login
            </Link>
          </p>
        </>
      );
  }

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default ForgotPassword;
