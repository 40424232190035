import React, { useContext } from "react";
import { TourContext } from "./TourProvider";
import StepOne from "./StepOne/StepOne";

// import styles from "./Tour.module.css";

const Tour = ({
  setTourActive,
  setShowingTourPopup,
  // currentTourStep,
  // setCurrentTourStep,
  handleShowSSVSettingsClick,
  handleShowSSVHelpClick,
}) => {
  const { currentStep } = useContext(TourContext);

  const tourContent = {
    0: (
      <StepOne
        setTourActive={setTourActive}
        setShowingTourPopup={setShowingTourPopup}
        // setCurrentTourStep={setCurrentTourStep}
        handleShowSSVSettingsClick={handleShowSSVSettingsClick}
        handleShowSSVHelpClick={handleShowSSVHelpClick}
      />
    ),
  };

  let content = tourContent[currentStep];

  return <>{content}</>;
};

export default Tour;
