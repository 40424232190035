import React from "react";
import useTitle from "../../../../../hooks/useTitle";

// import styles from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  useTitle("Privacy Policy");

  const content = <h2>Privacy Policy</h2>;

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default PrivacyPolicy;
