import React from "react";
import { useGoogleAuthMutation } from "./authApiSlice";
import GoogleButton from "react-google-button";

const GoogleAuth = ({ label }) => {
  const [googleAuth, { isLoading }] = useGoogleAuthMutation();

  const handleSignIn = async () => {
    try {
      await googleAuth();
    } catch (error) {
      console.error("Failed to sign in with Google:", error);
    }
  };

  const styles = {
    fontSize: "1.25rem",
    width: "100%",
  };

  return (
    <GoogleButton
      type="dark"
      onClick={handleSignIn}
      disabled={isLoading}
      label={label}
      style={styles}
    />
  );
};

export default GoogleAuth;
