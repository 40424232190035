import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const NavigationHelp = () => {
  const [showingNavigation, setShowingNavigation] = useState(false);

  return (
    <li className={`${styles.helpList__colItem} `}>
      <div
        className={styles.h3Row}
        onClick={() => setShowingNavigation(!showingNavigation)}
      >
        {showingNavigation ? (
          <FontAwesomeIcon
            icon={faChevronDown}
            size="1x"
            className={styles.icon}
          />
        ) : (
          <FontAwesomeIcon
            icon={faChevronRight}
            size="1x"
            className={styles.icon}
          />
        )}

        <h3 className={styles.h3}>Navigation</h3>
      </div>

      {showingNavigation && (
        <div className={`${styles.navigationHelp} ${styles.bottomPadding}`}>
          <section className={styles.helpSection}>
            <h4>Summary</h4>
            <div className={styles.helpSection__contents}>
              <ul className={styles.bulletedList}>
                <li>tap the logo in the top left to return to the Library</li>
                <li>
                  press the right chevron button in the toolbar at the bottom to
                  start reading
                </li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Book Location</h4>
            <div className={styles.helpSection__contents}>
              <p>The top center section shows:</p>
              <ul className={styles.bulletedList}>
                <li>book title</li>
                <li>chapter title (if any)</li>
                <li>current sentence</li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Fragments</h4>
            <div className={styles.helpSection__contents}>
              <p>Select a fragment by:</p>
              <ul className={styles.bulletedList}>
                <li>tapping it in the target sentence OR</li>
                <li>
                  tapping the left & right chevron buttons in the toolbar at the
                  bottom OR
                </li>
                <li>pressing the left & right arrow keys on your keyboard</li>
              </ul>
            </div>
          </section>

          <section className={styles.helpSection}>
            <h4>Sentences</h4>
            <div className={styles.helpSection__contents}>
              <p>Go to the next sentence by:</p>
              <ul className={styles.bulletedList}>
                <li>
                  tapping the up chevron to the right of the book title at the
                  top OR
                </li>
                <li>pressing the "n" key on your keyboard</li>
              </ul>
              <p>Go back to the previous sentence by:</p>
              <ul className={styles.bulletedList}>
                <li>
                  tapping the down chevron to the left of the book title at the
                  top OR
                </li>
                <li>pressing the "p" key on your keyboard</li>
              </ul>
            </div>
          </section>
        </div>
      )}
    </li>
  );
};

export default NavigationHelp;
