export const SENTENCES = {
  ids: ["1", "2"],
  entities: {
    1: {
      _id: "1",
      position: 1,
      translation: "This is a sentence in Spanish.",
    },
    2: {
      _id: "2",
      position: 2,
      translation: "This is another sentence in Spanish.",
    },
  },
};

export const WORD_INSTANCES = {
  ids: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  entities: {
    1: {
      _id: "1",
      sentence: "1",
      position: 1,
      text: "Esta",
      translation: "This",
      word: {
        lemma: "este",
        language: {
          name: "Spanish",
        },
      },
      pos: "pronoun",
      pronounType: "demonstrative",
      number: "singular",
      gender: "feminine",
      stem: {
        _id: "est",
        text: "est",
        pos: "pronoun",
        pronounType: "demonstrative",
      },
      ending1: {
        _id: "a",
        text: "a",
        gender: "feminine",
      },
      ending2: {},
      ending3: {},
    },
    2: {
      _id: "2",
      sentence: "1",
      position: 2,
      text: "es",
      translation: "null",
      stem: {
        _id: "es",
        text: "es",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    3: {
      _id: "3",
      sentence: "1",
      position: 3,
      text: "una",
      translation: "null",
      stem: {
        _id: "un",
        text: "un",
      },
      ending1: {
        _id: "a",
        text: "a",
      },
      ending2: {},
      ending3: {},
    },
    4: {
      _id: "4",
      sentence: "1",
      position: 4,
      text: "oración",
      translation: "null",
      stem: {
        _id: "oración",
        text: "oración",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    5: {
      _id: "5",
      sentence: "1",
      position: 5,
      text: "en",
      translation: "null",
      stem: {
        _id: "en",
        text: "en",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    6: {
      _id: "6",
      sentence: "1",
      position: 6,
      text: "Español",
      translation: "null",
      stem: {
        _id: "español",
        text: "español",
      },
      ending1: {},
      ending2: {},
      ending3: {},
      trailingPunctuation: ".",
    },
    7: {
      _id: "7",
      sentence: "2",
      position: 1,
      text: "Esta",
      translation: "This",
      pos: "pronoun",
      pronounType: "demonstrative",
      number: "singular",
      gender: "feminine",
      stem: {
        _id: "est",
        text: "est",
        pos: "pronoun",
        pronounType: "demonstrative",
      },
      ending1: {
        _id: "a",
        text: "a",
        gender: "feminine",
      },
      ending2: {},
      ending3: {},
    },
    8: {
      _id: "8",
      sentence: "2",
      position: 2,
      text: "es",
      translation: "null",
      stem: {
        _id: "es",
        text: "es",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    9: {
      _id: "9",
      sentence: "2",
      position: 3,
      text: "otra",
      translation: "null",
      stem: {
        _id: "otr",
        text: "otr",
      },
      ending1: {
        _id: "a",
        text: "a",
      },
      ending2: {},
      ending3: {},
    },
    10: {
      _id: "10",
      sentence: "2",
      position: 4,
      text: "oración",
      translation: "null",
      stem: {
        _id: "oración",
        text: "oración",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    11: {
      _id: "11",
      sentence: "2",
      position: 5,
      text: "en",
      translation: "null",
      stem: {
        _id: "en",
        text: "en",
      },
      ending1: {},
      ending2: {},
      ending3: {},
    },
    12: {
      _id: "12",
      sentence: "2",
      position: 6,
      text: "Español",
      translation: "null",
      stem: {
        _id: "español",
        text: "español",
      },
      ending1: {},
      ending2: {},
      ending3: {},
      trailingPunctuation: ".",
    },
  },
};

// Est(a) es un(a) oración en Español.
// Est(a) es otr(a) oración en Español.

// total # of unique fragments = 8
const user = "tourUser";
const state = "new";
const language = "Spanish";

export const VOCABULARIES = {
  ids: ["1", "2", "3", "4", "5", "6", "7", "8"],
  entities: {
    1: {
      _id: "1",
      user,
      state,
      language,
      fragment: "est",
    },
    2: {
      _id: "2",
      user,
      state,
      language,
      fragment: "a",
    },
    3: {
      _id: "3",
      user,
      state,
      language,
      fragment: "es",
    },
    4: {
      _id: "4",
      user,
      state,
      language,
      fragment: "un",
    },
    5: {
      _id: "5",
      user,
      state,
      language,
      fragment: "oración",
    },
    6: {
      _id: "6",
      user,
      state,
      language,
      fragment: "en",
    },
    7: {
      _id: "7",
      user,
      state,
      language,
      fragment: "español",
    },
    8: {
      _id: "8",
      user,
      state,
      language,
      fragment: "otr",
    },
  },
};
