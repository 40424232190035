import React, { useState, useEffect, useRef } from "react";
import {
  useGetUserProfileQuery,
  useUpdateUsernameMutation,
} from "../../profile/profileApiSlice";
import Filter from "bad-words";
import { USERNAME_REGEX } from "../../../config/regex";
import LoadMsg from "../../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTriangleExclamation,
  faCircleCheck,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./UndefinedUsername.module.css";
import FormErrMsg from "../../../components/shared/FormErrMsg/FormErrMsg";

const filter = new Filter();

const UndefinedUsername = () => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const errRef = useRef();
  const successRef = useRef();
  const usernameRef = useRef();

  const [errField, setErrField] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [successUsername, setSuccessUsername] = useState("");
  const [changedUsername, setChangedUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);

  const [updateUsername, { isLoading }] = useUpdateUsernameMutation();

  useEffect(() => {
    setErrMsg("");
  }, [changedUsername]);

  useEffect(() => {
    if (errMsg) {
      errRef.current.focus();
    }
  }, [errMsg]);

  useEffect(() => {
    if (successUsername) {
      successRef.current.focus();
    }
  }, [successUsername]);

  useEffect(() => {
    if (errField) {
      switch (errField) {
        case "username":
          usernameRef.current.focus();
          break;
        default:
          break;
      }
    }
  }, [errField]);

  useEffect(() => {
    setValidUsername(
      USERNAME_REGEX.test(changedUsername) && !filter.isProfane(changedUsername)
    );
  }, [changedUsername]);

  const handleUsernameInput = (e) => {
    if (errField) setErrField("");
    setChangedUsername(e.target.value);
  };

  const canSubmit = validUsername && !errMsg && !isLoading ? true : false;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (canSubmit) {
      try {
        const data = await updateUsername({
          username: changedUsername,
        }).unwrap();
        setErrMsg("");
        setSuccessUsername(data?.username);
        successRef.current?.focus();
        setChangedUsername("");
      } catch (err) {
        setErrMsg(err.data?.message);
        setErrField("username");
      }
    }
  };

  let content;

  if (isLoading) {
    content = <LoadMsg msg="Updating username..." size="2x" />;
  } else {
    content = (
      <div className={styles.container}>
        <div className={styles.flexColumn}>
          {!userProfile.username && !successUsername && (
            <h2 className={styles.warningMsg}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
              Set Username
            </h2>
          )}

          {errMsg && (
            <FormErrMsg
              elements={[
                <p
                  ref={errRef}
                  className="form__errorMsg"
                  aria-live="assertive"
                  role="alert"
                >
                  {errMsg}
                </p>,
              ]}
            />
          )}

          {successUsername && (
            <div
              ref={successRef}
              className="form__successMsg"
              aria-live="assertive"
              role="alert"
            >
              <p>
                Username updated to{" "}
                <span className={styles.username}>{successUsername}</span>
              </p>
              <FontAwesomeIcon icon={faCircleCheck} size="1x" bounce />
            </div>
          )}

          {/* edit username */}
          <form className="profileForm" onSubmit={handleSubmit}>
            <div className="form__col">
              <div className="form__col">
                <label className="form__label" htmlFor="username">
                  Username:
                </label>
                <p className="form__requirements">
                  3-20 characters, letters & numbers only
                </p>
              </div>
              <div className="form__row">
                <input
                  className={`form__input
                  ${
                    errField === "username"
                      ? "form__input--invalid"
                      : changedUsername && validUsername
                      ? "form__input--valid"
                      : changedUsername && !validUsername
                      ? "form__input--invalid"
                      : ""
                  }
                  `}
                  type="text"
                  id="username"
                  value={changedUsername}
                  onChange={handleUsernameInput}
                  autoComplete="off"
                  required
                  tabIndex="1"
                  ref={usernameRef}
                />
              </div>
            </div>

            {/* submit button by default since only 1 button in this form */}
            <button
              className={
                canSubmit
                  ? "form__submitButton"
                  : "form__submitButton--disabled"
              }
              disabled={!canSubmit}
              tabIndex="0"
            >
              Change username
            </button>
          </form>
        </div>
      </div>
    );
  }

  return content;
};

export default UndefinedUsername;
