import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials } from "../../features/auth/authSlice";

import { baseUrl } from "../../config/baseUrl";

// prepares all http reqs
const baseQuery = fetchBaseQuery({
  baseUrl: baseUrl,

  // reqs will include credentials (including cookies)
  credentials: "include",

  // puts access token from redux in auth headers if available
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

// wraps baseQuery, adds logic for handling token expiration
const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if forbidden (insufficient permissions)
  if (result?.error?.status === 403) {
    // send refresh token in secure cookie to get new access token to put into redux
    const refreshResult = await baseQuery("/auth/refresh", api, extraOptions);

    // if got token
    if (refreshResult?.data) {
      // store the new token in redux
      api.dispatch(setCredentials({ ...refreshResult.data }));

      // retry original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      return refreshResult;
    }
  }
  return result;
};

// to provide cache to store
export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Book", "Sentence", "User", "Vocabulary"], // todo: consider removing unneccessary tags
  endpoints: (builder) => ({
    keepUnusedDataFor: 60, // 60s (default)
  }),
});
