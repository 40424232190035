import React from "react";
import { Link } from "react-router-dom";
import smoothScrollToAnchor from "../../utils/smoothScrollToAnchor";

import styles from "./Newsletter.module.css";

const LaunchNewsletter = () => {
  return (
    <section id="newsletter" className={styles.section}>
      <article className={styles.article}>
        <p className={styles.date}>
          <time dateTime="2024-06-10">June 10, 2024</time>
        </p>

        <p className={styles.p} style={{ textIndent: "7.5%" }}>
          Today is the launch of my co-founder Noah’s and my brainchild, Iter
          Lexici. If you are reading this, thank you. This project is the
          culmination of both the many years I’ve spent studying and enjoying
          foreign languages as well as the result of Noah’s tireless and
          extremely thorough work in its development. I’d like to lay out here
          the reasons why we set out to create it as well as our vision for it
          in the future.
        </p>

        <p className={styles.p}>
          Philologists have been studying and successfully learning dead
          languages for centuries. While their willpower might be stronger than
          that of you or I, they cannot claim to have immersed themselves in the
          lands where those tongues could be heard on the streets. They can
          neither claim to have had any conversations with native speakers, so
          how did they learn?
        </p>

        <p className={styles.p}>
          They took a text in the language, started with the first word, looked
          it up, researched its morphology, concluded as to its role in its
          sentence, and then moved on to the next. The process became
          progressively easier for them as more words were amassed and more
          sentences were understood. Eventually, the philologist would develop a
          feel for the language and, in short order, be able to write and even
          speak in the dead language.
        </p>

        <p className={styles.p}>
          There is no other way to learn a language than by some variation of
          the above. Even if you memorize the 10,000 most frequently used words
          of a language and hard code its grammar rules into your head, you will
          not be able to synthesize the two fast enough for speech. Also, if you
          have met one who claims that speaking is the only way to learn, please
          extend to them on my behalf an invitation for a conversation in
          Navajo, followed by a brief discussion in Yoruba and then finally a
          farewell in Guaraní. I expect they will have something to contribute.
        </p>

        <p className={styles.p}>
          Before you object, of course physical immersion works very well, but
          it works because it is a variation of what the philologists have done
          with dead languages. Everything you read and everything you hear in a
          foreign country is, at least potentially,{" "}
          <em>comprehensible input</em>. It is possible that one can still fail
          to learn via immersion, but it is only so if they fail to comprehend.
          Put differently, reading the French street sign is not enough, someone
          must tell you what it means.
        </p>

        <p className={styles.p}>
          Fortunately, you will neither have to do the work of the philologists
          of old, the most-frequent-word memorizers, the grammar enthusiasts nor
          the speech inclined. You will neither have to finance travel to
          foreign lands. The texts of Iter Lexici will suffice more than a trip
          abroad because they are pre-processed by the most powerful
          technologies available and ready for your consumption. To help you
          further economize your time, our input for you will consist of the
          classic books that you’ve always wanted to sit down and read. These
          are the books that have stood the test of time for a reason – they are
          the most skillful use of the language in which they are written. Our
          first masterpiece will be Cervantes’ <em>Don Quixote</em>.
        </p>

        <p className={styles.p}>
          You may ask – why such complex literature? The answer is simple.
          Acquiring a language is like baking a cake. In the case of a foreign
          language, you have the recipe innately in your mind. All I need to do
          is to hand you the ingredients. There is no reason not to supply you
          with all of them at once. You, as a human being, are capable of
          selecting the ingredients that you need, as you need them. The
          ingredients here represent the various linguistic structures of which
          your target language consists. They are acquired unconsciously and in
          a natural order. This order represents the recipe, and it is already
          present in the human mind. In comparison, most apps are handing you
          one ingredient at a time with no knowledge of where you are in the
          recipe or even what the recipe is! The difference is that I accept
          that I do not possess this information, so I instead provide you with
          the full basket of ingredients that your natural propensity to acquire
          a language will take from unconsciously as you enjoy the masterwork.
        </p>

        <p className={styles.p}>
          <em>Don Quixote</em> will be released chapter-by-chapter over time
          with every single word accompanied by its precise counterpart in
          English and the entirety of its morphological significance available
          for your reference. Translation of course will be available also at
          the sentence level, but perhaps the most important feature is
          tracking. You will know exactly what amount of novel words and
          morphological fragments face you, and only you, in any given sentence
          with our one-of-a-kind system of fragmentation. In essence, it will be
          the most frictionless way to read a foreign language text ever
          contrived.
        </p>

        <p className={styles.p}>
          After some initial modifications based on user feedback, we will
          ambitiously expand the number of languages and texts offered. As we do
          so, all target languages will be offered as base languages. Literature
          is best enjoyed in its original language, and we don’t want to leave
          anyone out of the mix while we grow. This of course means that our
          next text will be an English-language original offered to speakers of
          Spanish.
        </p>

        <p className={styles.p}>
          If there is <em>anything</em> you want to see in the app or if there
          is <em>anything</em> you do not like about it,{" "}
          <Link className={`nowrap ${styles.link}`} to="/contact">
            email me.
          </Link>{" "}
          I envisioned an application that I would want to use for my learning
          purposes, and Noah brought it to life. We want to offer the same to
          our subscribers, for language learning <em>is not</em> academic and
          boring. I promise.
        </p>

        <p className={styles.p}>
          I sincerely hope that Iter Lexici will mark the end of your
          frustration with language learning and will represent a program that
          you can believe in. I believe in it, and I feel as if we are moving
          closer to creating the best map for the journey of the lexicon – the{" "}
          <em>Iter Lexici</em>.
        </p>

        <p className={styles.p}>
          Happy Reading,<br></br>
          Harrison Rowe<br></br>
        </p>

        <div className={styles.backToTop}>
          <Link
            onClick={smoothScrollToAnchor}
            className={styles.link}
            to="/#topBanner"
          >
            Back to top
          </Link>
        </div>
      </article>
    </section>
  );
};

export default LaunchNewsletter;
