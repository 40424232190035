import React, { useRef, useState, useEffect } from "react";
import { useCreateNewLanguageMutation } from "./adminApiSlice";
import LoadMsg from "../../components/shared/LoadMsg/LoadMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./AdminIndex.module.css";

const CreateNewLanguage = () => {
  const errRef = useRef();
  const successRef = useRef();

  const [showingForm, setShowingForm] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");

  const [name, setName] = useState("");
  const [isoCode, setIsoCode] = useState("");

  const [createNewLanguage, { isLoading }] = useCreateNewLanguageMutation();

  const clearMsgs = () => {
    setErrMsg("");
    setSuccessMsg("");
  };

  useEffect(() => {
    clearMsgs();
  }, [name, isoCode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    clearMsgs();
    try {
      const { message, isError } = await createNewLanguage({
        name,
        isoCode,
      }).unwrap();
      if (isError) {
        throw new Error(message);
      }
      setSuccessMsg(message);
      successRef.current?.focus();
    } catch (err) {
      console.error(err);
      setErrMsg(err?.data?.message || err.message);
      errRef.current?.focus();
    }
  };

  let content;

  if (isLoading) {
    content = (
      <div className={styles.loadingContainer}>
        <LoadMsg msg="creating language..." size="1x" />
      </div>
    );
  } else {
    content = (
      <section className={styles.section}>
        <div
          onClick={() => setShowingForm(!showingForm)}
          className={styles.headerRow}
        >
          {showingForm ? (
            <FontAwesomeIcon className={styles.icon} icon={faChevronDown} />
          ) : (
            <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
          )}
          <h2 className={styles.h2}>POST /languages</h2>
        </div>
        {showingForm && (
          <>
            {errMsg && (
              <p
                ref={errRef}
                className={styles.errMsg}
                aria-live="assertive"
                role="alert"
              >
                {errMsg}
              </p>
            )}

            {successMsg && (
              <p
                ref={successRef}
                className={styles.successMsg}
                aria-live="assertive"
                role="alert"
              >
                {successMsg}
              </p>
            )}

            <form className="form__admin" onSubmit={handleSubmit}>
              {/* name */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="name">
                    name: *
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    required
                    autoFocus
                    tabIndex="1"
                  />
                </div>
              </div>

              {/* isoCode */}
              <div className="form__col">
                <div className="form__row">
                  <label className="form__label" htmlFor="isoCode">
                    isoCode:
                  </label>
                </div>
                <div className="form__row grow">
                  <input
                    type="text"
                    className="form__input grow"
                    id="isoCode"
                    value={isoCode}
                    onChange={(e) => setIsoCode(e.target.value)}
                    autoComplete="off"
                    tabIndex="2"
                  />
                </div>
              </div>

              {/* submit button by default since only 1 button in this form */}
              <button className="form__submitButton" tabIndex="3">
                create language
              </button>
            </form>
          </>
        )}
      </section>
    );
  }

  return content;
};

export default CreateNewLanguage;
