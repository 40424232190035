import React from "react";
import { useGetUserProfileQuery } from "../profile/profileApiSlice";
import SSVSettings from "./SSVSettings";
import ConstructionMsg from "../../components/shared/ConstructionMsg/ConstructionMsg";
import useTitle from "../../hooks/useTitle";

import styles from "./Settings.module.css";

const Settings = () => {
  useTitle("Settings");

  const { data: userProfile } = useGetUserProfileQuery("userProfile");

  const content = (
    <section className={styles.container}>
      <h2 className={styles.h2}>Settings</h2>
      <div className={styles.settingsListContainer}>
        <h3 className={styles.h3}>Single Sentence View</h3>
        <SSVSettings userProfile={userProfile} />
      </div>
      <div className="grid-center">
        <ConstructionMsg />
      </div>
    </section>
  );

  return <main className="page-wrapper">{content}</main>;
};

export default Settings;
