import React, { useContext, useEffect, useCallback, useRef } from "react";
import { TourContext } from "../TourProvider";
import GrammarItem from "./GrammarItem/GrammarItem";
import { GRAMMAR_CONTENT } from "./grammarContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faToggleOn,
  faToggleOff,
  faVolumeHigh,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Info.module.css";
import tourStyles from "../Tour.module.css";

const Info = ({
  SENTENCES,
  currentSentenceId,
  currentWordInstances,
  bookTitle,
  selectedWord,
  selectedFragment,
  showTranslationSection,
  setShowTranslationSection,
  showGrammarSection,
  setShowGrammarSection,
  getVocabularyByFragmentId,
}) => {
  const { currentTask, setCurrentTask } = useContext(TourContext);

  const currentSentence = SENTENCES.entities[currentSentenceId];

  const selectedWordInstance = currentWordInstances.find(
    (wi) => wi._id === selectedWord
  );

  const audioRef = useRef(null);
  // initialize only once
  if (!audioRef.current) {
    audioRef.current = new Audio();
  }

  // memoized
  const onSentenceTTSClick = useCallback(() => {
    if (!audioRef.current.paused) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    audioRef.current.src = `/tutorial/sentence.wav`;

    console.log(audioRef.current.src);

    audioRef.current
      .play()
      .catch((error) => console.error("Error playing the audio file:", error));
  }, [bookTitle, currentSentence]);

  // setting1: autoPlayWordTTS
  // useEffect(() => {
  //   if (userProfile?.settings?.autoPlaySentenceTTS) {
  //     onSentenceTTSClick();
  //   }
  // }, [
  //   currentSentenceId,
  //   onSentenceTTSClick,
  //   userProfile?.settings?.autoPlaySentenceTTS,
  // ]);

  // TTS key mapping (s)
  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.key === "s") {
  //       event.preventDefault();
  //       event.stopPropagation();
  //       onSentenceTTSClick();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [onSentenceTTSClick]); // onSentenceTTSClick is stable now

  const selectedGrammarItem = (attribute) => {
    let fragment;
    let lemmaSelected = false;
    switch (selectedFragment) {
      case selectedWordInstance?.stem?._id:
        fragment = selectedWordInstance?.stem;
        lemmaSelected = true;

        break;
      case selectedWordInstance?.ending1?._id:
        fragment = selectedWordInstance?.ending1;
        break;
      case selectedWordInstance?.ending2?._id:
        fragment = selectedWordInstance?.ending2;
        break;
      case selectedWordInstance?.ending3?._id:
        fragment = selectedWordInstance?.ending3;
        break;
      default:
        return false;
    }
    if (!lemmaSelected && attribute === "lemma") {
      return false;
    } else if (
      fragment &&
      fragment[attribute] === selectedWordInstance[attribute]
    ) {
      return true;
    }
  };

  const grammarItemColor = (attribute) => {
    // language never styled
    if (
      attribute === "language" ||
      attribute === "expectedWord" ||
      attribute === "modernEquivalent"
    ) {
      return null;
    }

    // default color = `stem ${stemVocabulary} -> will be "seen" as soon as the user clicks`
    // let color = null;
    // let color = "stem seen"; // ?
    let color = "";

    const stemVocabulary = selectedWordInstance?.stem?._id
      ? getVocabularyByFragmentId(selectedWordInstance?.stem?._id)
      : null;
    // console.log(`stemVocabulary: ${stemVocabulary}`);

    const ending1Vocabulary = selectedWordInstance?.ending1?._id
      ? getVocabularyByFragmentId(selectedWordInstance?.ending1?._id)
      : null;
    // console.log(`ending1Vocabulary: ${stemVocabulary}`);

    const ending2Vocabulary = selectedWordInstance?.ending2?._id
      ? getVocabularyByFragmentId(selectedWordInstance?.ending2?._id)
      : null;
    // console.log(`ending2Vocabulary: ${stemVocabulary}`);

    const ending3Vocabulary = selectedWordInstance?.ending3?._id
      ? getVocabularyByFragmentId(selectedWordInstance?.ending3?._id)
      : null;
    // console.log(`ending3Vocabulary: ${stemVocabulary}`);

    if (selectedWordInstance[attribute]) {
      let vocabularyOfItem = null;

      // check stem attribute
      if (selectedWordInstance?.stem && selectedWordInstance?.stem[attribute]) {
        vocabularyOfItem = stemVocabulary.state;
        color = `stem ${vocabularyOfItem}`;
      }

      // check ending1 attribute
      else if (
        selectedWordInstance?.ending1 &&
        selectedWordInstance?.ending1[attribute]
      ) {
        vocabularyOfItem = ending1Vocabulary.state;
        color = `ending1 ${vocabularyOfItem}`;
      }

      // check ending2 attribute
      else if (
        selectedWordInstance?.ending2 &&
        selectedWordInstance?.ending2[attribute]
      ) {
        vocabularyOfItem = ending2Vocabulary.state;
        color = `ending2 ${vocabularyOfItem}`;
      }

      // check ending3 attribute
      else if (
        selectedWordInstance?.ending3 &&
        selectedWordInstance?.ending3[attribute]
      ) {
        vocabularyOfItem = ending3Vocabulary.state;
        color = `ending3 ${vocabularyOfItem}`;
      }

      // check vocabulary of grammar item
      // switch (vocabularyOfItem) {
      //   case "new":
      //     color = color + " new";
      //     break;
      //   case "seen":
      //     color = color + " seen";
      //     break;
      //   case "known":
      //     color = "known";
      //     break;
      //   default:
      //     break;
      // }
    }

    return color;
  };

  let grammarList = [];
  if (selectedWordInstance) {
    grammarList = GRAMMAR_CONTENT(selectedWordInstance);
  }
  const grammarContent = grammarList?.length
    ? grammarList.map((obj) => {
        return (
          <GrammarItem
            key={obj.itemName}
            selected={selectedGrammarItem(obj.item)}
            color={grammarItemColor(obj.item)}
            itemName={obj.itemName}
            itemDescription={obj.itemDescription}
            value={obj.value}
            valueDescription={obj.valueDescription}
          />
        );
      })
    : null;

  return (
    <div className={styles.gridInfo}>
      <div
        className={`
          ${
            currentTask === 7
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
          ${
            currentTask === 9
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
          ${
            currentTask === 10
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
          ${
            currentTask === 11
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
          ${
            currentTask === 21
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
          ${
            currentTask === 22
              ? `${styles.gridInfo_content__noScroll}`
              : `${styles.gridInfo_content}`
          }
        `}
      >
        {/* left side */}
        {/* <div className={styles.translationCol}> */}
        <div
          className={`${styles.translationCol}
            ${currentTask === 21 ? ` ${tourStyles.taskFocusBorder}` : ``}
            ${currentTask === 21.1 ? ` ${tourStyles.taskFocusBorder}` : ``}
            ${currentTask === 21.2 ? ` ${tourStyles.taskFocusBorder}` : ``}
            ${currentTask === 22 ? ` ${tourStyles.taskFocusBorder}` : ``}
            ${currentTask === 22.1 ? ` ${tourStyles.taskFocusBorder}` : ``}
        `}
        >
          <div
            className={`${styles.header} ${
              showTranslationSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={`${styles.headerToggle} 
              ${currentTask === 21 ? ` ${tourStyles.unclickable}` : ``}
              ${
                currentTask === 21.1
                  ? ` ${tourStyles.taskFocus__noPosition}`
                  : ``
              }
              ${
                currentTask === 21.2
                  ? ` ${tourStyles.taskFocus__noPosition}`
                  : ``
              }
              ${currentTask === 22 ? `${tourStyles.unclickable}` : ``}
              ${currentTask === 22.1 ? `${tourStyles.unclickable}` : ``}
              `}
              onClick={() => {
                if (currentTask === 21.1) {
                  setCurrentTask(21.2);
                }
                setShowTranslationSection(!showTranslationSection);
              }}
            >
              {showTranslationSection ? (
                <FontAwesomeIcon
                  icon={faToggleOn}
                  title="Hide Sentence Translation"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faToggleOff}
                  title="Show Sentence Translation"
                />
              )}
            </div>
            sentence
            <div
              className={`${styles.headerTTSButton}
            ${currentTask === 21 ? `${tourStyles.unclickable}` : ``}
            ${currentTask === 21.1 ? `${tourStyles.unclickable}` : ``}
            ${currentTask === 21.2 ? `${tourStyles.unclickable}` : ``}
            ${currentTask === 22 ? `${tourStyles.taskFocus__noPosition}` : ``}
            ${currentTask === 22.1 ? `${tourStyles.taskFocus__noPosition}` : ``}
            `}
            >
              <FontAwesomeIcon
                icon={faVolumeHigh}
                title='Play Sentence Text to Speech -  "S" Key'
                onClick={() => {
                  if (currentTask === 22) {
                    setCurrentTask(22.1);
                  }
                  onSentenceTTSClick();
                }}
              />
            </div>
          </div>
          {showTranslationSection && (
            <>
              <p className={styles.sentenceTranslation}>
                {currentSentence?.translation} {/** FIXME */}
              </p>
            </>
          )}
        </div>

        {/* right side */}
        <div
          className={`${styles.grammarCol}
            ${
              currentTask === 7
                ? `${styles.grammarCol__focus__noScroll} ${tourStyles.taskFocusBorder__unclickable}`
                : ``
            }
            ${
              currentTask === 8
                ? `${styles.grammarCol__focus__noScroll} ${tourStyles.taskFocusBorder__unclickable}`
                : ``
            }
            ${currentTask === 10 ? `${styles.grammarCol__focus__noScroll}` : ``}
            ${currentTask === 11 ? `${styles.grammarCol__focus__noScroll}` : ``}
        `}
        >
          {currentTask === 7 && <div className={tourStyles.clickBlocker}></div>}
          {currentTask === 8 && <div className={tourStyles.clickBlocker}></div>}

          <div
            className={`${styles.header} ${
              showGrammarSection ? `` : `${styles.header__off}`
            }`}
          >
            <div
              className={styles.headerToggle}
              onClick={() => setShowGrammarSection(!showGrammarSection)}
            >
              {showGrammarSection ? (
                <FontAwesomeIcon
                  icon={faToggleOn}
                  title="Hide Morphology Info"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faToggleOff}
                  title="Show Morphology Info"
                />
              )}{" "}
            </div>
            morphology
          </div>
          {showGrammarSection && grammarContent}
        </div>
      </div>
    </div>
  );
};

export default Info;
