import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNotification } from "../../../components/shared/AppLayout/notification/notificationSlice";
import SuccessMsg from "../../../components/shared/SuccessMsg/SuccessMsg";
import useTitle from "../../../hooks/useTitle";

import styles from "./Verification.module.css";

const VerificationSuccess = () => {
  useTitle("Verification Success");

  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const username = urlParams.get("username");
  const email = urlParams.get("email");

  if (username && email) {
    dispatch(
      setNotification({
        type: "success",
        message: "verified email",
        additionalFields: {
          username: username,
          email: email,
        },
      })
    );
  }

  const content = (
    <>
      <h2 className={styles.h2_success}>Success</h2>
      <SuccessMsg
        elements={[
          <p>
            Verified {email}. Please{" "}
            <Link to="/login" className={styles.link_success}>
              login
            </Link>
            , {username}.
          </p>,
        ]}
      />
    </>
  );

  return (
    <main className="page-wrapper">
      <div className="grid-center">
        <section className={styles.section}>{content}</section>
      </div>
    </main>
  );
};

export default VerificationSuccess;
