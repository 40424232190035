import React from "react";
import useTitle from "../../../../../hooks/useTitle";

// import styles from "./Help.module.css";

const Help = () => {
  useTitle("Help");

  const content = <h2>Help</h2>;

  return (
    <main className="page-wrapper">
      <div className="grid-center">{content}</div>
    </main>
  );
};

export default Help;
