import React, { useRef, useState, useEffect, useContext } from "react";
import { TourContext } from "../TourProvider";
import WordInstance from "./WordInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCheck,
  faRotateLeft,
  faEyeSlash,
  faEye,
  faChevronDown,
  faToggleOff,
  faToggleOn,
  faVolumeHigh,
  faGear,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./Target.module.css";
import tourStyles from "../Tour.module.css";

const Target = ({
  // currentTask,
  // setCurrentTask,
  currentWordInstances,
  selectedWord,
  selectedFragment,
  translationVisible,
  handleFragmentSelection,
  getVocabularyByFragmentId,
  handleStateReset,
  setTask13EffectRan,
}) => {
  const { currentTask, setCurrentTask, nextTask, prevTask } =
    useContext(TourContext);

  const olRef = useRef(null);
  const [olLeftOffset, setOlLeftOffset] = useState(null);
  const [olRightOffset, setOlRightOffset] = useState(null);

  const updateOffsets = () => {
    if (olRef.current) {
      const rect = olRef.current.getBoundingClientRect();
      setOlLeftOffset(rect.left);
      setOlRightOffset(rect.right);
    }
  };

  useEffect(() => {
    updateOffsets();
    window.addEventListener("resize", updateOffsets);

    return () => {
      window.removeEventListener("resize", updateOffsets);
    };
  }, [currentWordInstances]);

  const content = (
    <ol ref={olRef} className={`${styles.sentence}`}>
      {/* {(beginsParagraph || true) && (
        <li className={styles.paragraphIndentation}></li>
      )} */}
      {currentWordInstances.map((wordInstance, index) => {
        const {
          _id: wordInstanceId,
          text: wordInstanceText,
          word,
          translation,
          stem,
          ending1,
          ending2,
          ending3,
          leadingPunctuation,
          trailingPunctuation,
        } = wordInstance;

        let mutablewordInstanceText = wordInstanceText;
        // const fragments = [stem, ending1, ending2, ending3].filter(
        //   (obj) => obj !== null
        // );
        const fragments = [stem, ending1, ending2, ending3].filter(
          (obj) =>
            obj !== null && obj !== undefined && Object.keys(obj).length !== 0
        );

        const slices = fragments.map((fragment, index) => {
          const textLength = fragment?.text?.length;
          const textSlice = mutablewordInstanceText.slice(0, textLength);
          mutablewordInstanceText = mutablewordInstanceText.slice(textLength);
          const vocabulary = getVocabularyByFragmentId(fragment._id);

          return {
            fragmentId: fragment._id,
            text: textSlice,
            type: index ? `ending${index}` : "stem",
            vocabulary: vocabulary,
            isSelectedFragment: fragment._id === selectedFragment,
          };
        });

        const isSelectedWord = wordInstanceId === selectedWord;

        return (
          <WordInstance
            key={wordInstanceId}
            wordInstanceId={wordInstanceId}
            translation={translation}
            leadingPunctuation={leadingPunctuation}
            trailingPunctuation={trailingPunctuation}
            translationVisible={translationVisible}
            isSelectedWord={isSelectedWord}
            slices={slices}
            handleFragmentSelection={handleFragmentSelection}
            language={word?.language?.name}
            olLeftOffset={olLeftOffset}
            olRightOffset={olRightOffset}
            indent={false}
          />
        );
      })}
      <li className={styles.spacer}></li>
    </ol>
  );

  return (
    <div
      className={`${styles.gridTarget}
    ${currentTask === 0 ? `${tourStyles.guideFocus}` : ``}
    ${currentTask === 1 && ``}
    ${currentTask === 2 && ``}
    ${currentTask === 3 ? `${tourStyles.guideFocus}` : ``}
    ${currentTask === 4 && ``}
    ${currentTask === 4.1 && ``}
    ${currentTask === 16 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 17 ? `${tourStyles.guideFocus}` : ``}
    ${currentTask === 18 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 19 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 20 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 21 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 21.1 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 21.2 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 22 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 22.1 ? `${tourStyles.guideFocusNoOutline}` : ``}
    ${currentTask === 22.2 ? `${tourStyles.guideFocusNoOutline}` : ``}
    `}
    >
      {/* task 0 */}
      {currentTask === 0 && (
        <div className={tourStyles.guideDropdown0}>
          <p>
            The <span className={tourStyles.emphasize}>target sentence</span> is
            presented with our unique system of emphasis.
          </p>
          <div className={tourStyles.alignRight}>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next </span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 1 */}
      {currentTask === 1 && (
        <div className={tourStyles.guideDropdown1}>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={tourStyles.emphasize}>Fragments</span>, not
              words, are tracked in your vocabulary.
            </p>
            <p className={tourStyles.explainationPoint}>
              A fragment is a part of a word or a whole word.
            </p>
            <p className={tourStyles.explainationPoint}>
              Therefore, a word consists of one or more fragments.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => setCurrentTask(1.1)}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 1.1 - fragments continued */}
      {currentTask === 1.1 && (
        <div className={tourStyles.guideDropdown11}>
          <p className={tourStyles.primaryBold}>Fragments Continued:</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              A <span className={tourStyles.emphasize}>fragment</span> is{" "}
              <span className={tourStyles.primaryBold}>uniquely</span>{" "}
              determined by its{" "}
              <span className={tourStyles.primaryBold}>spelling</span> and{" "}
              <span className={tourStyles.primaryBold}>
                morphological significance
              </span>
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              We refer to the first fragment in a word as the{" "}
              <span className={tourStyles.emphasize}>stem</span>. The rest of
              the fragments in the word, if any, are called{" "}
              <span className={tourStyles.emphasize}>endings</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => setCurrentTask(1)}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => setCurrentTask(2)}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 2 */}
      {currentTask === 2 && (
        <div className={tourStyles.guideDropdown2}>
          <p>
            Select the first{" "}
            <span className={tourStyles.emphasize}>fragment</span> by tapping it
            in the <span className={tourStyles.emphasize}>target sentence</span>
            .
          </p>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => setCurrentTask(1.1)}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
          </div>
        </div>
      )}

      {/* task 3 */}
      {currentTask === 3 && (
        <div className={tourStyles.guideDropdown3}>
          <p>
            <span className={tourStyles.primaryBold}>Nicely done!</span>
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={tourStyles.primaryBold}>selected</span> the{" "}
              <span className={tourStyles.emphasize}>stem</span> in the first
              word.
            </p>
            <p className={tourStyles.explainationPoint}>
              The{" "}
              <span className={tourStyles.emphasize}>
                currently selected fragment
              </span>{" "}
              appears{" "}
              <span
                className={`${tourStyles.selectedFragment} ${tourStyles.primaryBold}`}
              >
                bordered
              </span>
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              Selecting a fragment causes{" "}
              <span className={tourStyles.primaryBold}>effects</span>...
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                handleStateReset();
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 4 */}
      {currentTask === 4 && (
        <div className={tourStyles.guideDropdown4}>
          <p className={tourStyles.primaryBold}>
            Effect 1 of Selecting a Fragment:
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The <span className={tourStyles.emphasize}>state</span> of your{" "}
              <span className={tourStyles.emphasize}>vocabulary</span> updates
              from <span className={tourStyles.emphasize}>new</span> to{" "}
              <span className={tourStyles.emphasize}>seen</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              This is indicated by the{" "}
              <span className={tourStyles.primaryBold}>text color change</span>{" "}
              from <span className={`new stem ${tourStyles.bold}`}>green</span>{" "}
              to <span className={`seen stem ${tourStyles.bold}`}>blue</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => setCurrentTask(4.1)}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 4.1 - vocabulary continued */}
      {currentTask === 4.1 && (
        <div className={tourStyles.guideDropdown41}>
          <p className={tourStyles.primaryBold}>Vocabulary Continued:</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              Your <span className={tourStyles.emphasize}>vocabulary</span>{" "}
              consists of{" "}
              <span className={tourStyles.emphasize}>fragments</span> in{" "}
              <span className={tourStyles.emphasize}>1 of 3 states</span>:{" "}
              <span className={tourStyles.emphasize}>new</span>,{" "}
              <span className={tourStyles.emphasize}>seen</span>, and{" "}
              <span className={tourStyles.emphasize}>known</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              <section className={tourStyles.helpSection}>
                <div className={tourStyles.helpSection__contents}>
                  <p>New stems appear green. </p>
                  <p className={`new stem ${tourStyles.example}`}>mundo</p>
                  <p>New endings appear as shades of yellow.</p>
                  <p className={tourStyles.example}>
                    <span className="new stem">confirm</span>
                    <span className="new ending1">ándo</span>
                    <span className="new ending2">se</span>
                  </p>
                </div>
              </section>
            </p>

            <p className={tourStyles.explainationPoint}>
              <section className={tourStyles.helpSection}>
                <div className={tourStyles.helpSection__contents}>
                  <p>Seen stems appear blue. </p>
                  <p className={`seen stem ${tourStyles.example}`}>mundo</p>
                  <p>Seen endings appear as shades of magenta.</p>
                  <p className={tourStyles.example}>
                    <span className="seen stem">confirm</span>
                    <span className="seen ending1">ándo</span>
                    <span className="seen ending2">se</span>
                  </p>
                </div>
              </section>
            </p>

            <p className={tourStyles.explainationPoint}>
              <section className={tourStyles.helpSection}>
                <div className={tourStyles.helpSection__contents}>
                  <p>Known stems & endings appear white.</p>
                  <p className={`known ${tourStyles.example}`}>los</p>
                </div>
              </section>
            </p>

            <p className={tourStyles.explainationPoint}>
              <section className={tourStyles.helpSection}>
                <div className={tourStyles.helpSection__contents}>
                  <p>
                    And, of course, words can appear with fragments in various
                    states.
                  </p>
                  <p className={tourStyles.example}>
                    <span className="new stem">algun</span>
                    <span className="seen ending1">a</span>
                  </p>
                  <p className={tourStyles.example}>
                    <span className="seen stem">desentrañ</span>
                    <span className="seen ending1">ar</span>
                    <span className="new ending2">les</span>
                  </p>
                </div>
              </section>
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => setCurrentTask(4)}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => setCurrentTask(5)}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 5 */}
      {currentTask === 5 && (
        <div className={tourStyles.guideDropdown5}>
          <p className={tourStyles.primaryBold}>
            Effect 2 of Selecting a Fragment:
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The <span className={tourStyles.emphasize}>word</span> to which
              the fragment belongs is{" "}
              <span className={tourStyles.primaryBold}>selected</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              This is indicated by the{" "}
              <div className={tourStyles.targetBg}>
                <span
                  className={`${tourStyles.selectedWord} ${tourStyles.primaryBold}`}
                >
                  slightly lighter word background color
                </span>
              </div>
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              The <span className={tourStyles.emphasize}>word</span>{" "}
              <span className={tourStyles.primaryBold}>
                text-to-speech (TTS)
              </span>{" "}
              automatically plays. You can{" "}
              <span className={tourStyles.primaryBold}>play</span> the{" "}
              <span className={tourStyles.emphasize}>word</span>{" "}
              <span className={tourStyles.primaryBold}>TTS</span> again by{" "}
              tapping{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faVolumeHigh}
              />{" "}
              in the <span className={tourStyles.emphasize}>toolbar</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => setCurrentTask(4.1)}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 6 */}
      {currentTask === 6 && (
        <div className={tourStyles.guideDropdown6}>
          <p className={tourStyles.primaryBold}>
            Effect 3 of Selecting a Fragment:
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The <span className={tourStyles.emphasize}>word translation</span>{" "}
              appears above the{" "}
              <span className={tourStyles.emphasize}>selected word</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              We consider the context of each word and select the translation to
              your native language that fits best.
            </p>
            <p className={tourStyles.explainationPoint}>
              You can toggle the display of the word translation by tapping{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faEyeSlash}
              />{" "}
              and{" "}
              <FontAwesomeIcon className={`${tourStyles.icon}`} icon={faEye} />{" "}
              in the <span className={tourStyles.emphasize}>toolbar</span>
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 7 */}
      {currentTask === 7 && (
        <div className={tourStyles.guideDropdown7}>
          <p className={tourStyles.primaryBold}>
            Effect 4 of Selecting a Fragment:
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The <span className={tourStyles.emphasize}>morphology</span> of
              the <span className={tourStyles.emphasize}>selected word</span>{" "}
              appears.
            </p>
            <p>Morphology is the study of the forms of words.</p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 8 */}
      {currentTask === 8 && (
        <div className={tourStyles.guideDropdown8}>
          <p className={tourStyles.primaryBold}>Morphology Continued:</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The <span className={`${tourStyles.emphasize}`}>attributes</span>{" "}
              of the selected word are the same color as the{" "}
              <span className={`${tourStyles.emphasize}`}>
                contributing fragment
              </span>
              , if applicable.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 9 */}
      {currentTask === 9 && (
        <div className={tourStyles.guideDropdown9}>
          <p className={tourStyles.primaryBold}>Morphology Continued:</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              Reveal the{" "}
              <span className={`${tourStyles.emphasize}`}>details</span> by
              tapping the{" "}
              <span className={`${tourStyles.emphasize}`}>attribute</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 10 */}
      {currentTask === 10 && (
        <div className={tourStyles.guideDropdown10}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You{" "}
              <span className={`${tourStyles.primaryBold}`}>
                revealed the details
              </span>{" "}
              of the{" "}
              <span className={`${tourStyles.emphasize}`}>
                language attribute
              </span>{" "}
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              The attribute is not colored because no single fragment is the
              sole contributor to this aspect of the morphology. It is more
              appropriate to think of this attribute as coming from the word
              instead of a fragment.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 11 */}
      {currentTask === 11 && (
        <div className={tourStyles.guideDropdown_11}>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>
                Hide the details
              </span>{" "}
              of the{" "}
              <span className={`${tourStyles.emphasize}`}>attribute</span> by
              tapping{" "}
              <FontAwesomeIcon
                className={tourStyles.icon}
                icon={faChevronDown}
              />
              .
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 12 */}
      {currentTask === 12 && (
        <div className={tourStyles.guideDropdown_12}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              Now you know the{" "}
              <span className={`${tourStyles.primaryBold}`}>
                effects selecting a fragment causes
              </span>
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              Next, let's cover how to{" "}
              <span className={`${tourStyles.primaryBold}`}>
                mark a fragment
              </span>{" "}
              as <span className={`${tourStyles.emphasize}`}>known</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 13 */}
      {currentTask === 13 && (
        <div className={tourStyles.guideDropdown_13}>
          <p className={tourStyles.primaryBold}>Mark a Fragment as Known</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>Mark</span> the
              selected fragment as{" "}
              <span className={`${tourStyles.emphasize}`}>known</span> by
              tapping{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faCheck}
              />{" "}
              in the <span className={`${tourStyles.emphasize}`}>toolbar</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div className={tourStyles.prevContainer} onClick={prevTask}>
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 14 */}
      {currentTask === 14 && (
        <div className={tourStyles.guideDropdown_14}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={`${tourStyles.primaryBold}`}>marked</span>{" "}
              the fragment as{" "}
              <span className={`${tourStyles.emphasize}`}>known</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              The{" "}
              <span className={`${tourStyles.primaryBold}`}>
                text color changed
              </span>{" "}
              to <span className={`${tourStyles.primaryBold}`}>white</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              Your{" "}
              <span className={`${tourStyles.emphasize}`}>
                known fragment count
              </span>{" "}
              (top right) updated to{" "}
              <span className={`${tourStyles.primaryBold}`}>1</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              The toolbar button automatically updated to{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faRotateLeft}
              />
              . You can use this to{" "}
              <span className={`${tourStyles.primaryBold}`}>revert</span> the
              state back to{" "}
              <span className={`${tourStyles.emphasize}`}>seen</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
                setTask13EffectRan(false);
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 15 */}
      {currentTask === 15 && (
        <div className={tourStyles.guideDropdown_15}>
          <p className={tourStyles.primaryBold}>Navigation</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You can also{" "}
              <span className={`${tourStyles.primaryBold}`}>select</span> a{" "}
              <span className={`${tourStyles.emphasize}`}>fragment</span> via{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faChevronLeft}
              />{" "}
              and{" "}
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faChevronRight}
              />{" "}
              in the <span className={`${tourStyles.emphasize}`}>toolbar</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faChevronLeft}
              />{" "}
              selects the{" "}
              <span className={`${tourStyles.emphasize}`}>
                previous fragment
              </span>
              , if there is one. Else, it navigates to the{" "}
              <span className={`${tourStyles.emphasize}`}>
                previous sentence
              </span>
              , if there is one.
            </p>
            <p className={tourStyles.explainationPoint}>
              <FontAwesomeIcon
                className={`${tourStyles.icon}`}
                icon={faChevronRight}
              />{" "}
              selects the{" "}
              <span className={`${tourStyles.emphasize}`}>next fragment</span>,
              if there is one. Else, it navigates to the{" "}
              <span className={`${tourStyles.emphasize}`}>next sentence</span>,
              if there is one.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 16 */}
      {currentTask === 16 && (
        <div className={tourStyles.guideDropdown_16}>
          <p className={tourStyles.primaryBold}>
            Navigate to the Next Fragment
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>Select</span> the{" "}
              <span className={`${tourStyles.emphasize}`}>next fragment</span>{" "}
              by tapping{" "}
              <FontAwesomeIcon
                className={tourStyles.icon}
                icon={faChevronRight}
              />{" "}
              in the <span className={`${tourStyles.emphasize}`}>toolbar</span>.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 17 */}
      {currentTask === 17 && (
        <div className={tourStyles.guideDropdown_17}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={`${tourStyles.primaryBold}`}>selected</span>{" "}
              the{" "}
              <span className={`${tourStyles.emphasize}`}>next fragment</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              The{" "}
              <span className={`${tourStyles.emphasize}`}>selection text</span>{" "}
              in the <span className={`${tourStyles.emphasize}`}>toolbar</span>{" "}
              updated.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 18 */}
      {currentTask === 18 && (
        <div className={tourStyles.guideDropdown_18}>
          <p className={tourStyles.primaryBold}>Sentence Navigation</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.emphasize}`}>Book title</span>,{" "}
              <span className={`${tourStyles.emphasize}`}>chapter</span>, and{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence location
              </span>{" "}
              information are displayed at the top.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 19 */}
      {currentTask === 19 && (
        <div className={tourStyles.guideDropdown_19}>
          <p className={tourStyles.primaryBold}>
            Navigate to the Next Sentence
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>Navigate</span> to
              the{" "}
              <span className={`${tourStyles.emphasize}`}>next sentence</span>{" "}
              by tapping{" "}
              <FontAwesomeIcon
                className={tourStyles.icon}
                icon={faChevronDown}
              />{" "}
              (top).
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 20 */}
      {currentTask === 20 && (
        <div className={tourStyles.guideDropdown_20}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={`${tourStyles.primaryBold}`}>navigated</span>{" "}
              to the{" "}
              <span className={`${tourStyles.emphasize}`}>next sentence</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              The{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence location information
              </span>{" "}
              updated.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 21 */}
      {currentTask === 21 && (
        <div className={tourStyles.guideDropdown_21}>
          <p className={tourStyles.primaryBold}>Sentence Translation</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              The{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence translation
              </span>{" "}
              section is located in the bottom left.
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => {
                setCurrentTask(21.1);
              }}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 21.1 */}
      {currentTask === 21.1 && (
        <div className={tourStyles.guideDropdown_211}>
          <p className={tourStyles.primaryBold}>
            Reveal the Sentence Translation
          </p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={tourStyles.primaryBold}>Reveal</span> the{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence translation
              </span>{" "}
              by tapping{" "}
              <FontAwesomeIcon className={tourStyles.icon} icon={faToggleOff} />{" "}
              (below).
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                setCurrentTask(21);
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div
              className={tourStyles.nextContainer}
              onClick={() => {
                setCurrentTask(22);
              }}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 21.2 */}
      {currentTask === 21.2 && (
        <div className={tourStyles.guideDropdown_212}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={tourStyles.primaryBold}>revealed</span> the{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence translation
              </span>
              .
            </p>
            <p className={tourStyles.explainationPoint}>
              You can{" "}
              <span className={tourStyles.primaryBold}>toggle the display</span>{" "}
              of the{" "}
              <span className={`${tourStyles.emphasize}`}>
                sentence translation
              </span>{" "}
              by tapping{" "}
              <FontAwesomeIcon className={tourStyles.icon} icon={faToggleOn} />{" "}
              and{" "}
              <FontAwesomeIcon className={tourStyles.icon} icon={faToggleOff} />
              .
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                setCurrentTask(21.1);
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => {
                setCurrentTask(22);
              }}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 22 */}
      {currentTask === 22 && (
        <div className={tourStyles.guideDropdown_22}>
          <p className={tourStyles.primaryBold}>Sentence Text-To-Speech</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>Play</span> the{" "}
              <span className={`${tourStyles.emphasize}`}>target sentence</span>{" "}
              <span className={`${tourStyles.primaryBold}`}>TTS</span> by{" "}
              tapping{" "}
              <FontAwesomeIcon
                className={tourStyles.icon}
                icon={faVolumeHigh}
              />{" "}
              (below).
            </p>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>
                Be sure your volume is up!
              </span>
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                setCurrentTask(21.2);
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div
              className={tourStyles.nextContainer}
              onClick={() => {
                setCurrentTask(22.1);
              }}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      {/* task 22.1 */}
      {currentTask === 22.1 && (
        <div className={tourStyles.guideDropdown_221}>
          <p className={tourStyles.primaryBold}>Nicely Done!</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              You <span className={`${tourStyles.primaryBold}`}>played</span>{" "}
              the{" "}
              <span className={`${tourStyles.emphasize}`}>target sentence</span>{" "}
              <span className={`${tourStyles.primaryBold}`}>TTS</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              You can <span className={`${tourStyles.primaryBold}`}>play</span>{" "}
              the{" "}
              <span className={`${tourStyles.emphasize}`}>target sentence</span>{" "}
              <span className={`${tourStyles.primaryBold}`}>TTS</span> anytime
              by tapping{" "}
              <FontAwesomeIcon
                className={tourStyles.icon}
                icon={faVolumeHigh}
              />
              .
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                setCurrentTask(22);
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div
              className={tourStyles.nextContainer}
              onClick={() => {
                setCurrentTask(23);
              }}
            >
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 23 */}
      {currentTask === 23 && (
        <div className={tourStyles.guideDropdown_23}>
          <p className={tourStyles.primaryBold}>Settings and Help</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>
                After completing this tutorial
              </span>
              , you can{" "}
              <span className={`${tourStyles.primaryBold}`}>access</span>{" "}
              <span className={`${tourStyles.emphasize}`}>settings</span> by{" "}
              tapping{" "}
              <FontAwesomeIcon className={tourStyles.icon} icon={faGear} />, and
              you can{" "}
              <span className={`${tourStyles.primaryBold}`}>access</span>{" "}
              <span className={`${tourStyles.emphasize}`}>help</span> by tapping{" "}
              <FontAwesomeIcon className={tourStyles.icon} icon={faQuestion} />{" "}
              (top right).
            </p>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>
                If you ever need to redo this tutorial, or if you want to learn
                more about
              </span>{" "}
              <span className={`${tourStyles.emphasize}`}>
                Single Sentence View
              </span>
              <span className={`${tourStyles.primaryBold}`}>, checkout</span>{" "}
              <span className={`${tourStyles.emphasize}`}>help</span>
              <span className={`${tourStyles.primaryBold}`}>!</span>
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div>
          </div>
        </div>
      )}

      {/* task 24 */}
      {currentTask === 24 && (
        <div className={tourStyles.guideDropdown_24}>
          <p className={tourStyles.primaryBold}>End of Tutorial</p>
          <div className={tourStyles.explainationContainer}>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>
                After completing this tutorial
              </span>
              , you will use the{" "}
              <span className={`${tourStyles.emphasize}`}>logo</span> to{" "}
              <span className={`${tourStyles.primaryBold}`}>exit</span> and
              return to your{" "}
              <span className={`${tourStyles.emphasize}`}>library</span>.
            </p>
            <p className={tourStyles.explainationPoint}>
              <span className={`${tourStyles.primaryBold}`}>Exit</span> the
              tutorial by tapping the{" "}
              <span className={`${tourStyles.emphasize}`}>logo</span> (top
              left).
            </p>
          </div>
          <div className={tourStyles.spaceBetween}>
            <div
              className={tourStyles.prevContainer}
              onClick={() => {
                prevTask();
              }}
            >
              <FontAwesomeIcon className={styles.icon} icon={faChevronLeft} />
              <span>previous</span>
            </div>
            {/* <div className={tourStyles.nextContainer} onClick={nextTask}>
              <span>next</span>
              <FontAwesomeIcon className={styles.icon} icon={faChevronRight} />
            </div> */}
          </div>
        </div>
      )}

      <div
        className={`${styles.sentenceContainer}
      `}
      >
        {currentTask === 0 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 1 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 2 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 3 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 4 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 16 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 17 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 18 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 19 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 20 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 21 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 21.1 && (
          <div className={tourStyles.clickBlocker}></div>
        )}
        {currentTask === 21.2 && (
          <div className={tourStyles.clickBlocker}></div>
        )}
        {currentTask === 22 && <div className={tourStyles.clickBlocker}></div>}
        {currentTask === 22.1 && (
          <div className={tourStyles.clickBlocker}></div>
        )}
        {currentTask === 22.2 && (
          <div className={tourStyles.clickBlocker}></div>
        )}
        {content}
      </div>
    </div>
  );
};

export default Target;
