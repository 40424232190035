import React from "react";
import { Link } from "react-router-dom";
import smoothScrollToAnchor from "../../../../utils/smoothScrollToAnchor";

import styles from "./TopBanner.module.css";

const TopBanner = () => {
  return (
    <div id="topBanner" className={styles.container}>
      <section>
        <p>
          Read our first{" "}
          <Link
            onClick={smoothScrollToAnchor}
            to="/#newsletter"
            className={styles.link}
          >
            newsletter!
          </Link>
        </p>
      </section>
    </div>
  );
};

export default TopBanner;
