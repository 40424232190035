import React from "react";
import {
  useGetUserProfileQuery,
  useUpdateUserHasLoggedInMutation,
} from "../../profile/profileApiSlice";
import VocabularyHelp from "./VocabularyHelp";
import NavigationHelp from "./NavigationHelp";
import TTSHelp from "./TTSHelp";
import TranslationsHelp from "./TranslationsHelp";
import GrammarHelp from "./GrammarHelp";
import ToolbarHelp from "./ToolbarHelp";
import CurrentSelectionHelp from "./CurrentSelectionHelp";
import SettingsHelp from "./SettingsHelp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";

import styles from "./HelpDrawer.module.css";

const HelpDrawer = ({
  showingSSVHelp,
  setShowingSSVHelp,
  showingTourPopup,
  setShowingTourPopup,
  setTourActive,
  currentTourStep,
  setCurrentTourStep,
}) => {
  const { data: userProfile } = useGetUserProfileQuery("userProfile");
  // const shouldFlash = !userProfile?.hasLoggedIn;

  const [updateUserHasLoggedIn] = useUpdateUserHasLoggedInMutation();

  // const handleUpdateUserHasLoggedIn = async () => {
  //   if (shouldFlash) {
  //     try {
  //       await updateUserHasLoggedIn({
  //         value: true,
  //       }).unwrap();
  //     } catch (err) {
  //       console.error("error updating use hasLoggedIn:", err);
  //     }
  //   }
  // };

  const handleHelpDrawerExitClick = async () => {
    // await handleUpdateUserHasLoggedIn();
    setShowingSSVHelp(false);
  };

  const handleStarTourClick = () => {
    // setTourActive(true);
    // setCurrentTourStep(0);
    setShowingTourPopup(true);
    setShowingSSVHelp(false);
  };

  return (
    <section className={styles.container}>
      <div className={styles.topBanner}>
        <h2>Help</h2>
        <FontAwesomeIcon
          icon={faCircleXmark}
          className={styles.exitIcon}
          onClick={handleHelpDrawerExitClick}
        />
      </div>

      <div className={styles.tour}>
        <button className={styles.tourButton} onClick={handleStarTourClick}>
          Tutorial
        </button>
      </div>

      <ul className={styles.helpList}>
        <VocabularyHelp
        // shouldFlash={shouldFlash}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <NavigationHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <TTSHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <TranslationsHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <GrammarHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <ToolbarHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <CurrentSelectionHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
        <SettingsHelp
        // shouldFlash={false}
        // handleUpdateUserHasLoggedIn={handleUpdateUserHasLoggedIn}
        />
      </ul>
    </section>
  );
};

export default HelpDrawer;
