import React, { memo, useEffect, useRef, useState } from "react";

import styles from "./Target.module.css";

const WordInstance = ({
  wordInstanceId,
  translation,
  leadingPunctuation,
  trailingPunctuation,
  translationVisible,
  isSelectedWord,
  slices,
  handleFragmentSelection,
  language,
  modernEquivalent,
  olLeftOffset,
  olRightOffset,
  indent,
}) => {
  const [alignmentClass, setAlignmentClass] = useState("");
  const tableRef = useRef(null);

  useEffect(() => {
    if (tableRef.current) {
      const rect = tableRef.current.getBoundingClientRect();
      const threshold = 10;
      const touchesLeft = rect.left <= olLeftOffset + threshold;
      const touchesRight = rect.right >= olRightOffset - threshold;

      if (touchesLeft) {
        setAlignmentClass(styles.alignLeft);
      } else if (touchesRight) {
        setAlignmentClass(styles.alignRight);
      } else {
        setAlignmentClass("");
      }
    }
  }, [olLeftOffset, olRightOffset]);

  return (
    <li
      key={wordInstanceId}
      className={indent ? `${styles.paragraphIndentation}` : ""}
    >
      <table ref={tableRef}>
        <tbody>
          {/* translation row */}
          <tr
            className={`${styles.translation} ${
              translationVisible[wordInstanceId] ? "" : "invisible"
            }`}
          >
            {leadingPunctuation && <td className={styles.punctuation}></td>}
            <td>{translation}</td>
            {trailingPunctuation && <td className={styles.punctuation}></td>}
          </tr>

          {/* word row */}
          <tr id={wordInstanceId} className={styles.wordInstance}>
            {leadingPunctuation && (
              <td className={styles.punctuation}>{leadingPunctuation}</td>
            )}
            <td className={`${styles.wordInstanceText} ${alignmentClass}`}>
              {slices.map((slice, index) => (
                <span
                  onClick={() =>
                    handleFragmentSelection(
                      wordInstanceId,
                      slice.fragmentId,
                      slice.vocabulary
                    )
                  }
                  id={slice.fragmentId}
                  key={slice.fragmentId}
                  className={`${styles.fragment}
                  ${isSelectedWord ? `${styles.selectedWord}` : ``}
                    ${
                      slice.isSelectedFragment
                        ? `${styles.selectedFragment}`
                        : ``
                    }
                    ${slice.type}
                    ${slice.vocabulary?.state || "new"}`}
                >
                  {slice.text}
                </span>
              ))}
            </td>
            {trailingPunctuation && (
              <td className={styles.punctuation}>{trailingPunctuation}</td>
            )}
          </tr>

          {/* language row */}
          {/* <tr className={styles.languageRow}>
            <td>
              {language !== "Spanish" && (
                <span className={styles.wordInstanceLanguage}>{language}</span>
              )}
            </td>
          </tr> */}

          {/* TODO: modern equivalent row */}
          <tr className={styles.modernEquivalentRow}>
            <td>
              {modernEquivalent && (
                <span className={styles.wordInstanceModernEquivalent}>
                  {modernEquivalent}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </li>
  );
};

const memoizedWordInstance = memo(WordInstance);

export default memoizedWordInstance;
