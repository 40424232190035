/** username:
 * may contain upper & lower case letters
 * may contain numbers
 * must be 3-20 characters in length
 */
export const USERNAME_REGEX = /^[A-Za-z0-9]{3,20}$/;

/** username length:
 * must be 3-20 characters
 */
export const USERNAME_LENGTH_REGEX = /^.{3,20}$/;

/** username contents:
 * may only container letters and numbers
 */
export const USERNAME_CONTENTS_REGEX = /^[A-Za-z0-9]+$/;

/** password:
 * must contain at least 1 lowercase letter
 * must contain at least 1 uppercase letter
 * must contain at least 1 number
 * must contain at least 1 of the following special characters: !@#$%^&*()_+-=[]{};':"\|,.<>/?
 */
export const PWD_REGEX =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{8,64}$/;

/** password length:
 * must be 8-64 characters
 */
export const PWD_LENGTH_REGEX = /^.{8,64}$/;

/** password contains:
 * at least 1 lowercase letter
 */
export const PWD_LOWERCASE_REGEX = /[a-z]/;

/** password contains:
 * at least 1 uppercase letter
 */
export const PWD_UPPERCASE_REGEX = /[A-Z]/;

/** password contains:
 * at least 1 number
 */
export const PWD_NUMBER_REGEX = /[0-9]/;

/** password contains:
 * at least 1 special character
 */
export const PWD_SPECIAL_REGEX = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

/** passwords match exactly */
export const PWD_EXACT_MATCH_REGEX = (value) =>
  new RegExp(`^${value.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")}$`);
