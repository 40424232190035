import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";

import styles from "./InputRequriementCheckMsg.module.css";

const InputRequirementCheckMsg = ({ value, regex, requirement }) => {
  let pass;
  let icon;
  let content;

  if (value && regex.test(value)) {
    // pass - requirement met
    pass = true;
    icon = <FontAwesomeIcon icon={faCircleCheck} />;
  } else {
    // fail - requirement not met
    pass = false;
    icon = <FontAwesomeIcon icon={faCircleXmark} />;
  }

  if (value) {
    content = (
      <p className={`${styles.container} ${pass ? styles.pass : styles.fail}`}>
        {icon} {requirement}
      </p>
    );
  }

  return content;
};

export default InputRequirementCheckMsg;
